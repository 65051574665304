import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { formItemLayout } from "../../utils";
import { Formik, useFormikContext } from "formik";
import { Form, FormItem, Input, Radio, DatePicker, Checkbox, InputNumber, TimePicker } from "formik-antd";
import { Form as AntForm, Space, Row, Col, Alert, message, Button, Tag, Modal } from "antd";
import { CSVReader } from "react-papaparse";
import { useSelector } from "react-redux";
import UploadImage from "./components/Upload";
import moment from "moment";
import AutoForm from "./components/AutoForm";
import { settingApi } from "../../apis";
import { ManualAlert } from "../../components/Manual";

const { TextArea } = Input;
const { RangePicker } = DatePicker;



const FormSchema = (translate) => Yup.object({
  name          : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  description   : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  discountType  : Yup.number().required(translate("system.message.required", "Please fill out this field!")),
  discountFactor: Yup.object({
    type   : Yup.number(),
    percent: Yup.number().when("type", {
      is       : 2,
      then     : Yup.number().max(99).typeError("Please fill out this field!").required(translate("system.message.required", "Please fill out this field!")),
      otherwise: Yup.number().nullable()
    }),
    flat: Yup.number().when("type", {
      is       : 1,
      then     : Yup.number().min(1, translate("system.message.required", "Please fill out this field!")).max(999999999, translate("system.message.required", "Please fill out this field!")).typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
      otherwise: Yup.number().nullable()
    }),
  }).nullable(),
  schedules          : Yup.array().of(Yup.string()).required(translate("system.message.required", "Please fill out this field!")),
  displayFlag        : Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  memberAvailMaxCount: Yup.number().when("memberAvailType", {
    is       : true,
    then     : Yup.number().min(0, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).max(999999, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
    otherwise: Yup.number().nullable()
  }),
  memberAvailType: Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  issueType      : Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  issueCount     : Yup.number()
    .when("issueType", {
      is       : true,
      then     : Yup.number().min(0).max(999999, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).typeError(translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).required(translate("system.message.required", "Please fill out this field!")),
      otherwise: Yup.number().nullable()
    })
    .when("itemType", (itemType, schema, data) => {
      if (itemType === 3 && data.originalValue > 1000){
        return Yup.number().min(0, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).max(1000, translate("coupon.error.issue.count.1000", "When target item is specified issue count must be lower than 1000!")).typeError("1000!");
      }
      return Yup.number().min(0, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).max(999999, translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).typeError(translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")).required(translate("system.message.required", "Please fill out this field!"));
    }),
  combineFlag    : Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  isMultiRankCond: Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  multiRankConds : Yup.string().when("isMultiRankCond", {
    is       : true,
    then     : Yup.string().required(translate("system.message.required", "Please fill out this field!")).typeError("Please fill out this field!"),
    otherwise: Yup.string()
  }),
  itemType: Yup.number().required(translate("system.message.required", "Please fill out this field!")),
  item    : Yup.string().when("itemType", (value) => {
    if (value === 3){
      return Yup.string().required(translate("system.message.required", "Please fill out this field!"));
    }
    return Yup.string().nullable();
  }).when((value) => {
    if (value) {
      let require = value.split(",").length;
      if (require > 3000) {
        return Yup.string().max(50, `${translate("coupon.item.limit", "At most 3000 product codes can be set !")}`).required();
      }
    }
  }),
  conditionStartValueRS003: Yup.number().when("conditionTypeCode", {
    is       : "RS003",
    then     : Yup.number().min(1, translate("condition.amount.message", "Up to 9 digits in single-byte numbers")).max(999999999, translate("condition.amount.message", "Up to 9 digits in single-byte numbers")).typeError("Please fill out this field!").required(translate("system.message.required", "Please fill out this field!")),
    otherwise: Yup.number().nullable()
  }),
  conditionStartValueRS004: Yup.number().when("conditionTypeCode", {
    is       : "RS004",
    then     : Yup.number().min(1, translate("condition.amount.message", "Up to 9 digits in single-byte numbers")).max(999999999, translate("condition.amount.message", "Up to 9 digits in single-byte numbers")).typeError("Please fill out this field!").required(translate("system.message.required", "Please fill out this field!")),
    otherwise: Yup.number().nullable()
  }),
  isAutoIssue: Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  autoIssue  : Yup.object({}).when("isAutoIssue", {
    is  : true,
    then: Yup.object({
      type      : Yup.string().required(translate("coupon.autoissue.type.required", "Please select one of the above!")),
      expireTime: Yup.string().when("type", {
        is       : "CUSTOM",
        then     : Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
        otherwise: Yup.string().nullable()
      }),
      expireDay: Yup.string().when("type", {
        is       : "CUSTOM",
        then     : Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
        otherwise: Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).nullable()
      }),
    }),
    otherwise: Yup.object({
      expireTime: Yup.string().nullable(),
      expireDay : Yup.string().nullable(),
    }).nullable()
  }).nullable()
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;
  React.useImperativeHandle(ref, () => ({
    async submitForm(translate) {
      await submitForm();
      let errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        message.error(translate("system.error.required", "Insert necessary fields first!"));
        return false;
      }
      return values;
    },
  }));
  return null;
});

const AUTOISSUE = {
  days        : [],
  pattern     : undefined,
  daySet      : undefined,
  type        : undefined,
  startTime   : undefined,
  expireDay   : undefined,
  expireTime  : undefined,
  isEndOfMonth: false,
};

export default React.forwardRef((props, ref) => {
  const csvRef = React.createRef();
  const { rakutenMemberLevels, lang } = useSelector(state => state.general);
  const { onSubmit, action, errors, details } = props;
  const [errorMessages, setErrorMessages] = React.useState([]);
  const submitRef = React.useRef();
  const { translate } = useTranslate();
  const [discountType, setDiscountType] = React.useState();
  const [autoIssue, setAutoIssue] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const getSystemSetting = async () => {
    try {
      const { payload } = await settingApi.get("COUPON_API_CHANGE");
      setShowAlert(payload);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };
  React.useEffect(() => {
    getSystemSetting();
  }, []);

  const [data, setData] = React.useState({
    name                    : undefined,
    description             : undefined,
    conditionStartValueRS004: undefined,
    conditionStartValueRS003: undefined,
    discountType            : 4,
    schedules               : [moment(new Date).add(1, "hour"), moment(new Date).add(30, "days")],
    discountFactor          : {
      percent: undefined,
      flat   : undefined,
      type   : 4
    },
    couponStatus       : null,
    isMultiRankCond    : false,
    displayFlag        : undefined,
    memberAvailMaxCount: undefined,
    issueCount         : 0,
    combineFlag        : false,
    conditionStartValue: 0,
    item               : "",
    itemType           : 4,
    isAutoIssue        : false,
    autoIssue          : AUTOISSUE,
    ...(action && (action[0] === "update"|| action[0] === "copy") ? action[1] : {}),
    memberAvailType    : (!!(action && (action[0] === "update"|| action[0] === "copy") && action[1].memberAvailMaxCount > 0)),
    issueType          : (!!(action && (action[0] === "update"|| action[0] === "copy") && action[1].issueCount > 0)),
    conditionTypeCode  : action && (action[0] === "update"|| action[0] === "copy") && action[1].conditionTypeCode ? action[1].conditionTypeCode : null,
    multiRankConds     : action && (action[0] === "update"|| action[0] === "copy") && action[1].multiRankConds ? action[1].multiRankConds : [""],
  });
  const [disabled, setDisabled] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    moment.tz.setDefault("Asia/Tokyo");
    if (data.autoIssue?.couponStatus === "HOLD"){
      setDisable(true);
    }
    return () => {
      moment.tz.setDefault();
    };
  }, []);

  const renderWeek = (day) => {
    switch (day) {
      case 1: {
        return translate("system.constant.monday", "Monday");
      }
      case 2: {
        return translate("system.constant.tuesday", "Tuesday");
      }
      case 3: {
        return translate("system.constant.wednesday", "Wednesday");
      }
      case 4: {
        return translate("system.constant.thursday", "Thursday");
      }
      case 5: {
        return translate("system.constant.friday", "Friday");
      }
      case 6: {
        return translate("system.constant.saturday", "Saturday");
      }
      case 7: {
        return translate("system.constant.sunday", "Sunday");
      }
      default: {
        return day;
      }
    }
  };

  const disablePastDates = (current) => {
    return current.valueOf() < moment().subtract(1, "day").valueOf("day");
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {

      let isValid = await submitRef.current.submitForm(translate);

      return isValid;
    }
  }));

  React.useEffect(()=>{
    setErrorMessages(errors);
  }, [errors]);

  React.useEffect(() => {
    if ((action && action[0] !== "copy" && action[1].couponStatus !== "NEW" && moment(action && action[1].couponStartDate).subtract(1, "hour").format("YYYY-MM-DD HH:mm")) || action && action[1].couponStatus === null) {
      setDisabled(true);
    }
    if (details === true){
      setDisabled(true);
    }
  }, [action]);

  return (
    <Container>
      {showAlert && <Alert style={{ marginBottom: 5 }} type="warning" message={
        <div>
          {translate("coupon.api.change.alert", "coupon.api.change.alert")}
        </div>
      } />}
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={() => { }}>
        {({ values, setFieldTouched, setFieldValue, errors }) => {
          return (
            <Form {...formItemLayout}>
              {errorMessages && errorMessages?.length > 0 ?(
                <ErrorMessage>
                  <Alert message={
                    <div>
                      {translate("coupon.error.code", "Rakuten error code :")}
                      {
                      errorMessages?.map(error => {
                        return (<span className="error"> {error.code} ,</span>);
                      })
                      }
                    </div>
                  } type="error" showIcon />
                </ErrorMessage >
              ):<span></span>}
              <table border="1px #F0F0F0">
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div className="head-container">
                        <div className="label">{translate("coupon.name", "Coupon name")}<b>{translate("coupon.required", "Required!")}</b></div>
                        <FormItem name="name">
                          <Input disabled={disabled} name="name" placeholder={translate("coupon.name.placeholder", "Insert coupon name")} />
                        </FormItem>
                        <p className="footer-description">{translate("coupon.name.alert", "Insert coupon name")}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td className="table-title">{translate("coupon.isAutoIssue", "Auto issue")}</td>
                    <td className="contents">
                      <div style={{ marginLeft: "10px" }}>
                        <FormItem name="isAutoIssue">
                          <Checkbox disabled={disabled} name="isAutoIssue" onChange={(e) => {setFieldValue("autoIssue", AUTOISSUE);}}> {translate("coupon.isAutoIssue.select", "Auto issue")} </Checkbox>
                        </FormItem>
                        <FormItem name="autoIssue.type">
                          <Radio.Group disabled={disabled || disable || !values?.isAutoIssue} name="autoIssue.type" onChange={(e) => {
                            setFieldValue("autoIssue.type", e.target.value);
                          }}>
                            <Space direction="vertical">
                              <RadioItem disabled={disabled} className={values?.autoIssue?.type === "BASIC" ? "click" : "unClick"}>
                                <Radio value={"BASIC"}>
                                  <div className="radio-item title">
                                    <div className="title">{translate("coupon.autotype.basic", "Basic")}</div>
                                  </div>
                                  <div className="input-desc">{translate("coupon.autotype.basic.desc", "It will be automatically renewed for a validity period of 60 days. Automatic renewal will occur 6 days before the expiration date.")}</div>
                                </Radio>
                              </RadioItem>
                              <RadioItem disabled={disabled} className={values?.autoIssue?.type === "CUSTOM" ? "click" : "unClick"}>
                                <Radio value={"CUSTOM"}>
                                  <div className="radio-item title">
                                    <div className="title">{translate("coupon.autotype.custom", "Custom")}</div>
                                  </div>
                                  <div className="input-desc">{translate("coupon.autotype.custom.desc", "It will start creating after 2 days from now!")}</div>
                                </Radio>
                              </RadioItem>
                            </Space>
                          </Radio.Group>
                        </FormItem>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {values.autoIssue?.type === "CUSTOM" ?
                            <RadioItem>
                              <div className="radio-item">
                                <div className="title">{translate("coupon.custom.resenddays", "Resend days")}</div>
                                <div style={{ width: "30px" }}></div>
                                {values.autoIssue.pattern === "MONTH" && <div className="title">{translate("coupon.pattern.month", "Monthly")}</div> }
                                {values.autoIssue.pattern === "WEEK" && <div className="title">{translate("coupon.pattern.week", "Weekly")}</div> }
                                <div className="title">
                                  {values.autoIssue?.days.length < 1 && <Tag color="orange">{translate("coupon.custom.resenddays.notfound", "Days has not been selected!")}</Tag>}
                                  {
                                      values.autoIssue?.days.sort((a, b) => a - b).map(day => {
                                        if (values.autoIssue?.pattern === "WEEK"){
                                          return <Tag >{renderWeek(day)}</Tag>;
                                        }
                                        if (day === 32){
                                          return <Tag >{translate("regular.endofmonth", "End")}</Tag>;
                                        }
                                        return <Tag>{day}</Tag>;
                                      })
                                  }
                                </div>
                                <Button disabled = {disable} className="title" onClick={() => setAutoIssue(true)}>{translate("system.update", "Edit")}</Button>
                              </div>
                            </RadioItem>
                            : null}
                          {values.autoIssue?.type === "CUSTOM" ?
                            <Space direction="vertical">
                              <RadioItem>
                                <div className="radio-item">{translate("coupon.autotype.custom.time.desc", "Insert expire time and start time as Asia/Tokyo timezone")}</div>
                              </RadioItem>
                              <RadioItem>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.expiretime", "Duration")}</div>
                                  <div style={{ width: "30px" }}></div>
                                  <div className="title">{translate("coupon.expiretime.before", "After")}</div>
                                  <FormItem name="autoIssue.startTime">
                                    <TimePicker disabled format={(date) => date.tz("Asia/Tokyo").format("HH:mm")} name={"autoIssue.startTime"} showSecond={false} />
                                  </FormItem>
                                  <div style={{ width: "5px" }}></div>
                                  <div className="title">{translate("coupon.expiretime.from", "from")}</div>
                                  <FormItem name="autoIssue.expireDay">
                                    <Number disabled = {disable} min={0} name="autoIssue.expireDay" placeholder="0" />
                                  </FormItem>
                                  <div className="title">{translate("coupon.expiretime.after", "days")}</div>
                                  <FormItem name="autoIssue.expireTime">
                                    <TimePicker disabled = {disable} format={(date) => date.tz("Asia/Tokyo").format("HH:mm")} name={"autoIssue.expireTime"} showSecond={false} />
                                  </FormItem>
                                  <div className="title">{translate("coupon.expiretime.hours", "hours")}</div>
                                </div>
                              </RadioItem>
                            </Space>
                            : null}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-title">{translate("coupon.description.label", "Description")}</td>
                    <td className="fields contents">
                      <div style={{ display: "flex", width: "100%", padding: "10px" }}>
                        <FormItem name="description">
                          <TextArea disabled={disabled} showCount maxLength={100} rows={4} name="description" placeholder={translate("coupon.description.placeholder", "Coupon description")} />
                        </FormItem>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-title">{translate("coupon.discount.type", "Sale plan")} </td>
                    <td className="contents">
                      <FormItem name="discountType">
                        <Radio.Group disabled={disabled} name="discountType" onChange={(e) => {
                          setFieldValue("discountFactor.type", e.target.value);
                          switch (e.target.value) {
                            case 1:
                            case 3:
                            default: {
                              setFieldValue("isMultiRankCond", false);
                              setFieldValue("itemType", 4);
                              // setFieldValue("item", "");
                            }
                          }
                        }}>
                          <Space direction="vertical">
                            <RadioItem disabled={disabled} className={values.discountType === 1 ? "click" : "unClick"}>
                              <Radio value={1}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.discount.type.flat", "Flat rate")}</div>
                                  <div>
                                    <FormItem name="discountFactor.flat">
                                      <Number min={1} name="discountFactor.flat" disabled={(values.discountType !== 1) || disabled} placeholder="2000" className="Input" />円OFF
                                    </FormItem>
                                    <div className="input-desc">{translate("coupon.discount.type.flat.message", "Up to 9 digits in single-byte numbers")}</div>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem disabled={disabled} className={values.discountType === 2 ? "click" : "unClick"}>
                              <Radio value={2}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.discount.type.percent", "Percent")}</div>
                                  <div>
                                    <FormItem name="discountFactor.percent">
                                      <Number max={99} min={1} name="discountFactor.percent" disabled={(values.discountType !== 2) || disabled} placeholder="30" className="Input" />%OFF
                                    </FormItem>
                                    <div className="input-desc">{translate("coupon.discount.type.percent.message", "Up to 99 from 1 in single-byte number")}</div>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>
                            <RadioItem disabled={disabled} className={values.discountType === 4 ? "click" : "unClick"}>
                              <Radio value={4}>
                                <div className="radio-item title">
                                  <div className="title">{translate("coupon.discount.type.delivery", "Free delivery")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>
                  <tr >
                    <td className="table-title">{translate("coupon.date", "Valid period【Required!】")}</td>
                    <td className="contents">
                      <div className="group-item wrap">
                        <FormItem disabled={disabled} name="schedules">
                          <RangePicker
                            disabled={disabled}
                            allowClear={false}
                            disabledDate={disablePastDates}
                            name="schedules"
                            value={[values.schedules[0] && moment(values.schedules[0]),
                              values.schedules[0] && moment(values.schedules[1])]}
                            showTime={{
                              hideDisabledOptions: true,
                              defaultValue       : [moment("00:00:00", "HH:mm:ss"), moment(Date().now, "HH:mm:ss")],
                            }} />
                        </FormItem>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="table-title">{translate("coupon.displayFlag", "Display Flag【Required!】")}</td>
                    <td className="contents">
                      <FormItem name="displayFlag">
                        <Radio.Group disabled={disabled} name="displayFlag">
                          <Space direction="inline">
                            <RadioItem className={values.displayFlag === true ? "click" : "unClick"}>
                              <Radio value={true}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.displayFlag.show", "Show")} </div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem className={values.displayFlag === false ? "click" : "unClick"}>
                              <Radio value={false}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.displayFlag.hide", "Hide")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>


                  <tr>
                    <td className="table-title">{translate("coupon.image", "Image")}</td>
                    <td className="contents">
                      <div className="group-item">
                        <FormItem name="image">
                          <Input disabled={disabled} placeholder="https://image.rakuten.co.jp/hansoku/cabinet/xxx.jpg" name="image" />
                          <div className="input-desc">
                            {translate("coupon.image.message", "Image size 240×240(Image must be square)")}
                          </div>
                        </FormItem>
                        <div style={{ pointerEvents: disabled === true?"none":"" }}>
                          <UploadImage
                            onResult={(e) => {setFieldValue("image", e?.imageUrl); }}
                            action="/api/coupon/upload"
                            text={translate("coupon.image.upload", "Upload to R-Cabinet")} />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="table-title">{translate("coupon.per.user", "Use limit per user")}</td>
                    <td className="contents">
                      <FormItem name="memberAvailType">
                        <Radio.Group disabled={disabled} name="memberAvailType" onChange={(e) => {
                          if (e.target.value)
                            setFieldValue("memberAvailMaxCount", undefined);
                        }}>

                          <Space direction="vertical">
                            <RadioItem className={values.memberAvailType === true ? "click" : "unClick"}>
                              <Radio value={true}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.per.user.true", "Set limit")}</div>
                                  <div style={{ marginLeft: "30px", marginTop: "7px" }}>{translate("coupon.per.user.placeholder-1", " Per user")}</div>
                                  <div>
                                    <FormItem name="memberAvailMaxCount">
                                      <Number min={1} value={values.memberAvailMaxCount === 0 ? undefined : values.memberAvailMaxCount} name="memberAvailMaxCount" disabled={(values.memberAvailType === false) || disabled} placeholder="2000" className="Input" />{translate("coupon.per.user.placeholder-2", " times available")}
                                    </FormItem>
                                    <div className="input-desc">{translate("coupon.per.user.message", "Up to 6 digits in single-byte numbers")}</div>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>
                            <RadioItem className={values.memberAvailType === false ? "click" : "unClick"}>
                              <Radio value={false}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.per.user.unlimited", "Unlimited")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-title">{translate("coupon.issue.count", "Coupon issue count")}</td>
                    <td className="contents">
                      <FormItem name="issueType">
                        <Radio.Group disabled={disabled} name="issueType" onChange={(e) => {
                          if (e.target.value){
                            setFieldValue("issueCount", undefined);
                          } else {
                            setFieldValue("issueCount", 0);
                          }
                        }}>
                          <Space direction="vertical">
                            <RadioItem className={values.issueType === true ? "click" : "unClick"}>
                              <Radio value={true}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.issue.count.true", "Set limit")}</div>
                                  <div>
                                    <FormItem name="issueCount">
                                      <Number min={1} value={values.issueCount === 0 ? undefined : values.issueCount} name="issueCount" disabled={(values.issueType === false) || disabled} placeholder="2000" className="Input" />{translate("coupon.issue.count.placeholder", "in total")} &nbsp;{translate("coupon.issue.count.message", "Up to 6 digits in single-byte numbers")}
                                    </FormItem>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem className={values.issueType === false ? "click" : "unClick"}>
                              <Radio value={false}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.issue.count.unlimited", "Unlimited")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>

                  <tr>
                    <td className="table-title">{translate("coupon.combineFlag", "Combine Flag【Required!】")}</td>
                    <td className="contents">
                      <FormItem name="combineFlag">
                        <Radio.Group disabled={disabled} name="combineFlag">
                          <Space direction="inline">
                            <RadioItem className={values.combineFlag === false ? "click" : "unClick"}>
                              <Radio value={false}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.combineFlag.notcombine", "Don't combine")}</div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem className={values.combineFlag === true ? "click" : "unClick"}>
                              <Radio value={true}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.combineFlag.combine", "Combine")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>

                  <tr>
                    <td className="table-title">{translate("condition.title", "Buy amount・Buy count")}</td>
                    <td className="contents">
                      <FormItem name="conditionTypeCode">
                        <Radio.Group disabled={disabled} name="conditionTypeCode" onChange={(e) => {
                          setTimeout(() => {
                            switch (e.target.value) {
                              case "RS003":
                                setFieldValue("conditionStartValueRS004", undefined);
                                break;
                              case "RS004":
                                setFieldValue("conditionStartValueRS003", undefined);
                                break;
                              default: {
                                setFieldValue("conditionStartValue", 0);
                              }
                            }
                          }, 50);

                        }}>
                          <Space direction="vertical">
                            <RadioItem disabled={disabled} className={values.conditionTypeCode === "RS003" ? "click" : "unClick"}>
                              <Radio value={"RS003"}>
                                <div className="radio-item">
                                  <div className="title">{translate("condition.amount", "Buy amount")}</div>
                                  <div>
                                    <FormItem name="conditionStartValueRS003">
                                      <Number min={1} name="conditionStartValueRS003" disabled={(values.conditionTypeCode !== "RS003") || disabled} placeholder="2000" className="Input" />{translate("condition.amount.placeholder", "Usable when purchase is above set amount (tax included)")}
                                    </FormItem>
                                    <div className="input-desc">{translate("condition.amount.message", "Up to 9 digits in single-byte numbers")}</div>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem className={values.conditionTypeCode === "RS004" ? "click" : "unClick"}>
                              <Radio value={"RS004"}>
                                <div className="radio-item">
                                  <div className="title">{translate("condition.count", "Buy count")}</div>
                                  <div>
                                    <FormItem name="conditionStartValueRS004">
                                      <Number min={1} name="conditionStartValueRS004" disabled={(values.conditionTypeCode !== "RS004") || disabled} placeholder="30" className="Input" />{translate("condition.count.placeholder", "Usable when bought items are more than set amount")}
                                    </FormItem>
                                    <div className="input-desc">{translate("condition.count.message", "Up to 9 digits in single-byte numbers")}</div>
                                  </div>
                                </div>
                              </Radio>
                            </RadioItem>

                            <RadioItem className={!values.conditionTypeCode ? "click" : "unClick"}>
                              <Radio value={null}>
                                <div className="radio-item">
                                  <div className="title">{translate("condition.noset", "Don't specify")}</div>
                                </div>
                              </Radio>
                            </RadioItem>

                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </td>
                  </tr>

                  <tr>
                    <td className="table-title">{translate("coupon.item.title", "Products")}</td>
                    <td className={"contents"}>
                      <FormItem name="itemType" >
                        <Radio.Group name="itemType" disabled={values.discountType === 4 || disabled} onChange={(e)=>{
                          console.log(values.item);
                          setTimeout(() => {
                            setFieldTouched("item", false);
                            setFieldValue("item", undefined);
                          }, 50);
                        }} >

                          <div className={`${values.discountType === 4 ? "disabled" : ""}`}>
                            <Space direction="inline">
                              <RadioItem className={values.itemType === 4 ? "click" : "unClick"}>

                                <Radio value={4}>
                                  <div className="radio-item">
                                    <div className="title">{translate("coupon.item.noset", "Don't specify")}</div>
                                  </div>
                                </Radio>
                              </RadioItem>

                              <RadioItem className={values.itemType === 3 ? "click" : "unClick"}>
                                <Radio value={3}>
                                  <div className="radio-item">
                                    <div className="title">{translate("coupon.item.set", "Specify")}</div>
                                  </div>
                                </Radio>
                              </RadioItem>
                            </Space>
                          </div>

                        </Radio.Group>
                      </FormItem>
                      <div className="space">
                        {values.itemType === 3 && <CSVUpload csvRef={csvRef} setFieldValue={setFieldValue} values={values} translate={translate} />}

                        <div className="input-desc">{translate("coupon.item.message", "Half-width line break delimiter csv or txt format Character code: Shift_JIS")}</div>
                        <FormItem name="item">
                          <TextArea
                            disabled={values.itemType !== 3 || disabled}
                            rows={6}
                            placeholder={translate("coupon.item.placeholder", "Product management No.")}
                            name="item" />
                        </FormItem>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-title">{translate("coupon.meberRank", "Member level")}</td>
                    <td className="contents">
                      <FormItem name="isMultiRankCond">
                        <Radio.Group disabled={disabled} name="isMultiRankCond" onChange={(e) => {
                          // if (e.target.value)
                          //   setFieldValue("multiRankConds", [""]);
                        }}>
                          <Space direction="inline">
                            <RadioItem className={values.isMultiRankCond === false ? "click" : "unClick"}>
                              <Radio value={false}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.meberRank.noset", "Don't specify")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                            <RadioItem className={values.isMultiRankCond === true ? "click" : "unClick"}>
                              <Radio value={true}>
                                <div className="radio-item">
                                  <div className="title">{translate("coupon.meberRank.set", "Specify")}</div>
                                </div>
                              </Radio>
                            </RadioItem>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                      <div className="group-item inline">
                        <FormItem name="multiRankConds">
                          <Checkbox.Group name="multiRankConds" disabled={(values.isMultiRankCond === false)}>
                            <Row>
                              {rakutenMemberLevels.map(item => (
                                <Col span={8}>
                                  <Checkbox value={item.code}>{item[lang.toLowerCase()]}</Checkbox>
                                </Col>
                              ))}
                            </Row>
                          </Checkbox.Group>
                        </FormItem>
                      </div>
                    </td>
                  </tr>
                  <Modal
                    visible={autoIssue}
                    maskClosable ={false}
                    width="750px"
                    title={translate("coupon.auto.days", "Auto days") }
                    onCancel={() => setAutoIssue(false)}
                    destroyOnClose
                    footer={false}
                  >
                    <AutoForm
                      initialValues={values.autoIssue}
                      onCancel={() => setAutoIssue(false)}
                      onSubmit={(e) => {
                        console.log("modalform_value", e);
                        setFieldValue("autoIssue.days", e.days);
                        setFieldValue("autoIssue.pattern", e.pattern);
                        setFieldValue("autoIssue.daySet", e.daySet);
                        setFieldValue("autoIssue.isEndOfMonth", e.isEndOfMonth);
                        setFieldValue("autoIssue.startTime", e.startTime);
                        setAutoIssue(false);
                      }}/>
                  </Modal>
                </tbody>
              </table>
              <SubmitForm ref={submitRef} translate={translate} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const CSVUpload =({ csvRef, values, setFieldValue, translate })=> {

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (csvRef.current) {
      csvRef.current.open(e);
    }
  };

  return (
    <CSVContainer>
      <CSVReader
        ref={csvRef}
        noProgressBar
        noClick
        noDrag
        onFileLoad={(value)=> {
          let file= "";
          value.forEach(item => {
            item.data.forEach(data => {
              file =file+"\n"+data;
            });
          });
          setFieldValue("item", file.replaceAll("\n\n", "\n").trim());
        }}
      >
        {({ file }) => (
          <div className="container">
            <button
              type='button'
              onClick={handleOpenDialog}
              style={{
                width : "100px",
                height: "30px"
              }}
            >
              {translate("system.upload", "Upload file")}
            </button>
            <div className="file-name">
              {file && file.name}
            </div>
          </div>
        )}
      </CSVReader>
    </CSVContainer>
  );
};

const CSVContainer = styled.div`
  .container {
    display:flex;
  }
  .file-name {
    display:flex;
    align-items :center;
    margin-left : 10px;
  }
`;

// onFileLoad={(value)=> {
//   let file= "";
//   value.forEach(item => {
//     item.data.map(data => {
//       file =file+"\n"+data;
//     });
//   });
//   setFieldValue("item", file.replaceAll("\n\n", "\n").trim());
// }}

const Number = styled(InputNumber)`
  background-color : #FFFFE0;
`;

const ErrorMessage = styled.div`
  color:red;
  margin-bottom: 12px;
  width: 888px;
  .error {
    font-size: 12px;
    margin-right: 12px;
  }
  .space { 
    margin: 0px 10px;
  }
  .ant-alert-error {
    margin-top : 5px;
  }
`;
const RadioItem = styled.div`
  border-radius: 8px;
  padding: 10px;
  display: inline-flex;
  &&:hover {
    background-color: #FDFDEA;
  }

  &.click {
    background-color:#DEEAFF;
  }
  &.unClick {
    background-color:#fff;
  }
  
  .ant-input {
    width :100px;
  }

  .ant-input-number {
    margin: 0 10px;
  }

  .input-desc {
    font-size :12px;
    margin : 5px 10px;
  }

  .radio-item { 
    display:flex;
     .title {
      margin :7px 0;
      margin-right: 10px;
    }
  }
`;

const Container = styled.div`
 .ant-col-sm-16 {
    max-width : 100% !important;
  }  
  .ant-input-number-disabled {
    background-color : #F1F1F1 !important;
  }
  .head-container {
    padding: 15px;
    .ant-col-sm-16 {
      max-width : 100% !important;
    }
    .footer-description {
      font-size: 10px;
      color :#9DA5A4;
      margin-top: 6px;
    }
    .label {
      b {
        color :red;
      }
    }
    .ant-form-item {
      margin : 0px !important;
    }
    background-color: #FAFAFA;
  }
  table { 
    border:solid 1px  #F0F0F0;
  }
  .disabled {
    pointer-events: none;
  }
  .table-title {
    padding-left :5px;
    background-color : #FAFAFA;
  }
  .space {
    margin-left :14px;
  }
  .group-item {
    display :flex;
    margin-left :14px;
    .ant-form-item { 
      margin-bottom: 0px !important;
      margin-right: 14px;
    }
    .ant-col-sm-16 {
      width :100% !important;
      max-width : 100% !important;
    }

    .ant-col-xs-24 {
      width :100% !important;
      max-width : 100% !important;
    }

    .item {
      width: 174px;
    }
    .inline {
      display:flex;
    }
  }

  .input-desc {
    font-size :12px;
    margin-top :  10px;
    margin-bottom :  10px;
  }
  .contents {
    height:100%;
    padding :10px;
    padding-left: 24px;
    .ant-radio {
      margin-top : 9px;
    }
    .wrap {
      height: 32px;
    }
  }
  .fields {
    width :80%;
    .textarea {
      font-size: 14px;
    }
    .ant-row{
      width: 100%;
    }
    .ant-col-sm-16 {
      width :100% !important;
      max-width : 100% !important;
    }
  }
`;