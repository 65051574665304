
import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import { debug as debugApi } from "../../apis";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { DatePicker, Empty, message, Space } from "antd";
import ManualPaymentForm from "./components/ManualPaymentForm";
import moment from "moment";
import { MyTable } from "../../components";
import styled from "styled-components";
import RakutenOrderSyncForm from "./components/RakutenOrderSyncForm";
import { date } from "yup";
import ProcessReportSyncForm from "./components/ProcessReportSyncForm";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const [loading, setLoading, ] = React.useState({});
  const [action, setAction] = React.useState(null);
  const [processReportModal, setProcessReportModal] = React.useState(null);
  const [rakutenOrderSync, setRakutenOrderSync] = React.useState(false);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: undefined,
    endDate  : undefined
  });
  const [data, setData] = React.useState({
    customerId: undefined,
    amount    : undefined
  });
  const columns = useHeader({
    tableTitles: {
      no         : translate("system.number", "No."),
      userAccount: translate("payment.useraccount", "User account"),
      amountTax  : translate("payment.amounttax", "Amount (Tax included)"),
      paymentDate: translate("new translate", "Transaction date"),
      paymentID  : translate("payment.paymentid", "Payment ID"),
      details    : translate("payment.details", "Details"),
      status     : translate("system.status", "Status"),
      retry      : translate("payment.retry", "Retry"),
      action     : translate("system.action", "Action"),
      view       : translate("system.detail", "Detail"),
      orderID    : translate("payment.orderId", "Order ID"),
      gmoTranDate: translate("new.translate", "GMO tran date"),
    },
  });

  const onRakutenReview = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.rakutenReview();
    setLoading(state => ({ ...state, [0]: false }));
  };
  const onCouponDelete = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.couponDelete();
    setLoading(state => ({ ...state, [0]: false }));
  };
  const onRakutenOrderReset = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.rakutenOrderReset();
    setLoading(state => ({ ...state, [0]: false }));
  };
  const onLogFix = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.logFix();
    setLoading(state => ({ ...state, [0]: false }));
  };
  const scenarioCancel = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.scenarioCancel();
    setLoading(state => ({ ...state, [0]: false }));
  };
  const onRichmenuDebug = async () => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.richmenuDebug();
    setLoading(state => ({ ...state, [0]: false }));
  };
  // const onRakutenOrder = async () => {
  //   setLoading(state => ({ ...state, [1]: true }));
  //   await debugApi.rakutenOrder();
  //   setLoading(state => ({ ...state, [1]: false }));
  // };

  const onOrderMerge = async ()=> {
    setLoading(state => ({ ...state, [1]: true }));
    await debugApi.orderMerge();
    setLoading(state => ({ ...state, [1]: false }));
  };

  const onOrderReset = async ()=> {
    setLoading(state => ({ ...state, [1]: true }));
    await debugApi.orderReset();
    setLoading(state => ({ ...state, [1]: false }));
  };


  const couponIssue = async ()=> {
    setLoading(state => ({ ...state, [1]: true }));
    await debugApi.couponIssue();
    setLoading(state => ({ ...state, [1]: false }));
  };

  const couponResend = async ()=> {
    setLoading(state => ({ ...state, [1]: true }));
    await debugApi.couponResend();
    setLoading(state => ({ ...state, [1]: false }));
  };
  const rakutenCheck = async ()=> {
    setLoading(state => ({ ...state, [1]: true }));
    await debugApi.rakutenCheck();
    setLoading(state => ({ ...state, [1]: false }));
  };
  const onProcessReport = async (data) => {
    setLoading(state => ({ ...state, [0]: true }));
    await debugApi.processReport(data);
    setLoading(state => ({ ...state, [0]: false }));
  };

  const onCancel = async () => {
    setAction(false);
    setProcessReportModal(false);
  };

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const clearRange = () => {
    setFilters({ ...filters, startDate: null, endDate: null });
    setRangePicker({ startDate: null, endDate: null });
  };

  const manualPayment = async (data) => {
    try {
      let res = await debugApi.manualPayment(data);
      message.success(translate("system.message.success", "Successful"));
      onCancel(true);
    } catch (err){
      message.error(translate("system.message.error", err.message));
    }
  };

  const onRakutenOrder = async (data) => {
    try {
      await debugApi.rakutenOrder({ date: moment(data?.date).format("YYYY-MM-DD") });
      message.success(translate("system.message.success", "Successful"));
      onCancel(true);
    } catch (err){
      message.error(translate("system.message.error", err.message));
    }
  };

  return (
    <Container>
      <PageContainer>
        <PageHeader title={translate("adminmenu.debug", "Debug")} extra={[
          <Button key={0} loading={loading[0]} type="primary" onClick={rakutenCheck}>{translate("debug.rakutenCheck", "RMS Key check")}</Button>,
          <Button key={1} loading={loading[0]} type="primary" onClick={onRakutenReview}>{translate("debug.rakutenReview", "Rakuten review")}</Button>,
          <Button key={2} loading={loading[2]} type="primary" onClick={onOrderReset}>{translate("debug.orderReset", "Rakuten follower Recalculate")}</Button>,
          <Button key={0} loading={loading[0]} type="primary" onClick={onRakutenOrderReset}>{translate("debug.connectionreset", "Rakuten connection reset")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={() => setRakutenOrderSync(true)}>{translate("debug.rakutenOrder", "Rakuten order")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={onOrderMerge}>{translate("debug.orderMerge", "Order Merge")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={onLogFix}>{translate("debug.logfix", "Log fix")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={couponIssue}>{translate("debug.couponissue", "Coupon Issue")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={couponResend}>{translate("debug.couponresend", "Coupon Resend")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={onCouponDelete}>{translate("debug.delete.coupon", "Delete coupon")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={scenarioCancel}>{translate("debug.orderReset", "Follower scenario reset")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={onRichmenuDebug}>{translate("debug.richMenu", "Richmenu Schedule reset")}</Button>,
          <Button key={1} type="primary" onClick={() => setAction(["create"])} icon={<PlusOutlined />}>{translate("debug.payment", "Manual Payment")}</Button>,
          <Button key={1} loading={loading[1]} type="primary" onClick={() => setProcessReportModal(["processreport"])} icon={<PlusOutlined />}>{translate("debug.processreport", "Process report")}</Button>
        ]} />
        <PageHeader title={translate("debug.payment", "Manual Payment")}/>
        <PageContent>

          <Space>
            <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
            <Button type="default" onClick={() => clearRange()}>{translate("system.clear", "Clear")}</Button>
          </Space>

          <div style={{ marginTop: "10px" }}>

            <MyTable
              locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
              filters={filters}
              rowKey={(record) => record._id}
              columns={columns}
              loadData={debugApi.manualPaymentHistory}
              // onResult={(item) => setExportData(item.rows)}
            />
          </div>
        </PageContent>

        <Modal
          width={"790px"}
          maskClosable={false}
          title={action && action[0] === "create" ? translate("debug.payment.new", "New payment") : translate("plan.add", "Create new plan")}
          visible={!!action}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <ManualPaymentForm onSubmit={manualPayment} onCancel={onCancel}/>
        </Modal>
        <Modal
          maskClosable={false}
          title={translate("debug.rakutenorder.sync", "Rakuten order sync")}
          visible={!!rakutenOrderSync}
          onCancel={() => {
            setRakutenOrderSync(false);
          }}
          destroyOnClose
          footer={false}>
          <RakutenOrderSyncForm onSubmit={onRakutenOrder} onCancel={() => {setRakutenOrderSync(false);}}/>
        </Modal>
        <Modal
          maskClosable={false}
          title={translate("debug.processreport.create", "Process report")}
          visible={processReportModal && processReportModal[0] === "processreport"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <ProcessReportSyncForm onSubmit={onProcessReport} onCancel={onCancel}/>
        </Modal>
      </PageContainer>
    </Container>
  );
};

const Container = styled.div`
  .hidden-btn {
    display:none;
  }
`;

const useHeader = ({ tableTitles, onAction, translate, user }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.userAccount,
      render: (value, row, index) => (
        <div>{row.customer && row.customer.companyName && row.customer.companyName}</div>
      )
    },
    {
      title : tableTitles.amountTax,
      render: (value, row, index) => {
        return (<div>{row.amount.toLocaleString("jp-JP") + "￥"}</div>);
      }
    },
    {
      title : tableTitles.paymentID,
      // dataIndex: "gmoTranID",
      render: (record) => {
        return (<div>{ record.gmoTranID === undefined ? (record._id) : (record.gmoTranID) }</div>);
      }
    },
    {
      title : tableTitles.orderID,
      // dataIndex: "gmoTranID",
      render: (record) => {
        return (<div>{record._id}</div>);
      }
    },
    {
      title : tableTitles.paymentDate,
      render: (value, row, index) => {
        return (<div>{moment(row.paymentStatusDate).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")}</div>);
      }
    },
    {
      title : "Error",
      render: (value, row, index) => {
        return (<div>{row.paymentStatusError ? (<p style={{ color: "red" }}>{row.paymentStatusError}</p>) : "-"}</div>);
      }
    },
  ];
};

