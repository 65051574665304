import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { account as accountApi, auth as authApi } from "../../apis";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { Button, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState();
  const { account, user } = useSelector(state=>state.general);

  const reload = React.useCallback(async (signal) => {
    let res = await accountApi.get(params.id, { signal });

    setAction(["update", res]);
  }, [params.id]);

  const logout = async () => {
    await authApi.logout();

    dispatch({
      type   : "auth.change",
      payload: {
        logged: false
      }
    });
    dispatch({ type: "auth.logout", payload: "" });
  };

  console.log("* * * * * * * * * * * * * * ");
  console.log(action);
  console.log("* * * * * * * * * * * * * * ");

  const title = () => {
    if (action && action[1].ecmid && !action[1].lineChannelId){
      return translate("line.connect", "Connect Line official account");
    }
    return translate("line.edit", "Edit Line Official Account");
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title={title()} onBack={() => history.push("/")} extra={[
        account && !account.lineChannelId && account?.ecmaccesskey && user.role === "ACCOUNT"?(<Button onClick={logout}>{translate("new.translate", "Log out")}</Button>):(<div></div>)
      ]}/>

      <PageContent>
        {
          (action && !action[1].lineChannelId && action && action[1].ecmaccesskey) ? <AccountTemplate/> : null
        }

        {action && <Form action={action} />}

      </PageContent>
    </PageContainer>
  );
};
// https://lp.l-seg.com/image/admin/popup.png?random_string=12313

export const AccountTemplate = () => {
  return (
    <div style={{ padding: "0 2rem" }}>
      <div style={{ fontSize: "1.25rem", fontWeight: 900 }}>ECマスターズLSEGとは</div>
      <div style={{ paddingLeft: "1rem" }}>
        <p>ECマスターズLSEGは、楽天RMSとデータ連携可能なLINEメッセージ配信を便利にするツールです。</p>
        <span style={{ fontWeight: 900 }}>【こんな事が出来ます】</span><br />
        <div style={{ paddingLeft: "1rem" }}>
          <span>★LINEのお友だち情報と、楽天購入データを紐づけ</span><br />
          <span>★LINEメッセージで送る、楽天クーポンを自動発行＆自動更新</span><br />
          <span>★楽天注文時に自動サンキューメッセージ、レビュー投稿時のサンキューク－ポン送付</span><br />
          <span style={{ paddingLeft: "1rem" }}>・・・など</span><br />
        </div>
        <br />
        <span style={{ fontWeight: 900 }}>【LSEG利用初期設定の流れ】</span><br />
        <div style={{ paddingLeft: "1rem" }}>
          <span>LSEGをご利用頂くには、LINE公式アカウントが必要です。</span><br />
          <span>楽天ショップで利用する場合は「R-SNS」に申請することを推奨しています。</span><br />
          <br />
          <span>①R-SNSに申請(取得まで約10営業日)</span><br />
          <span style={{ paddingLeft: "1rem" }}>※RMS「コミュニティ」より申請を行ってください</span><br />
          <span>②作成されたLINE公式アカウントに
            <Popover placement="right" content={
              <div style={{
                height  : "600px",
                overflow: "scroll"
              }}>
                <img style={{ width: "400px" }} src={`https://lp.l-seg.com/image/admin/popup.png?random_string=${Math.floor(Math.random() * 1000000)}`}/>
              </div>
            } trigger="click">
              <a>管理者認証</a>
            </Popover>
            を行う</span><br />
          <span>③LSEGとLINE公式アカウントを連携</span>
        </div>
        <br /><span style={{ fontWeight: 900 }}>【初期設定の方法】</span>
        <div style={{ paddingLeft: "1rem" }}>
          <span>既にLINE公式をお持ちの場合、初期設定は下記のマニュアルに従って操作を行ってください。</span><br />
          <br />
          <span>▼LSEG(エルセグ) 初期設定マニュアル</span><br />
          <span><a href="https://rdr.jp/nnZK7uE" target="_blank">https://rdr.jp/nnZK7uE</a></span><br />
          <br />
          <span>▼動画マニュアル(ワークショップ動画)</span><br />
          <span><a href="https://ec-masters.net/video/play/3l938k" target="_blank">https://ec-masters.net/video/play/3l938k</a></span></div><br /></div>
    </div>
  );
};