import React, { useMemo } from "react";
import { Button, Space } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import NoImage from "./images/no-image-profile.png";
import { usePermission, useTranslate } from "../../hooks";
import { Sticker } from "..";
import { Video, Image } from "./components";
import Thumbnail from "../Message/components/Thumbnail";
import renderHTML from "react-render-html";
import Carousel from "./components/Carousel";
import Imagemap from "./components/Imagemap";


export default ({ type, theme = "dark", ontestSend }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { checkRole } = usePermission();
  const { preview } = useSelector(state => state.message);
  const { message } = useSelector(state => state.message);
  const { cooperationTypes, lang } = useSelector(state => state.general);
  const { account } = useSelector(state => state.general);
  const [divider, SetDivider] = React.useState(false);
  const [showDivider, SetShowDivider] = React.useState(true);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  const renderTextEmoji = ({ text, characters }) => {
    if (!characters || characters.length === 0) {
      return text && text !== "" ? text : "...";
    }

    let size = "22px";

    if (characters && characters.length === 1 && !(typeof characters[0] === "string" || characters[0] instanceof String))
      size = "66px";

    let html = characters.reduce((accumulator, iterator) => {
      if (!iterator) return accumulator;

      if (typeof iterator === "string" || iterator instanceof String)
        return accumulator + `<span>${iterator}</span>`;
      if (typeof iterator[0] === "string" || iterator[0] instanceof String)
        return accumulator + `<span>${iterator[0]}</span>`;
      return accumulator + `<img style="width:${size}" src="/emoji/${iterator.productId}/${iterator.emojiId}_key.png" alt="[${iterator.altText}]" />`;
    }, "");

    return renderHTML(`${html.replace(/(?:\n|\n)/g, "<br />")}`);
  };

  const onPreview = (preview) => {
    dispatch({
      type   : "message.preview",
      payload: preview
    });
  };
  

  const onShowDivider = () => {
    SetShowDivider(!showDivider)
  };

  return (
    <PreviewContainer className={`${type} ${theme}`}>
      <PreviewHeader className={theme}>
        <Space>
          <a className="nav" onClick={() => onPreview(!preview)} href="#">
            {type === "fixed" &&
              (preview === true ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              ))}{" "}
            {translate("system.messagepre.preview", "Message preview")}
          </a>
        </Space>
        {ontestSend && disabled ? <Button onClick={() => ontestSend()} style={{ borderRadius: '5px', background : '#4a575d', color:"white" }}>{translate("message.preview.send.test", "Send test")}</Button> : null}
      </PreviewHeader>
      <PreviewAccountInfo>
        {account?.displayName}
        <Button onClick={() => onShowDivider()} hidden={!divider} className="button">{translate(!showDivider ? "message.preview.hide.imagemappreview": "message.preview.show.imagemappreview", "Show divider")}</Button>
      </PreviewAccountInfo>
      <PreviewContent
        style={{ marginRight: "20px" }}
        className={`${preview && "open"} ${type}`}>
        {message &&
          message?.children &&
          message?.children.map((child, index) => {
            console.log("child_messagepreview =>",index,child);
            return (
              <Message key={index}>
                {((()=>{
                  if(child.type === "imagemap"){
                    return (<div></div>)
                  }
                  else if(child.type === "carousel"){
                    return (<div></div>)
                  }
                   return(
                    <div className="avatar">
                      <img src={NoImage} />
                    </div>
                   )
                }))()}
                <div className="message">
                  <div>
                    {(() => {
                      switch (child.type) {
                        case "text":
                          return <p className="body">{renderTextEmoji(child)}</p>;
                        case "sticker":
                          return (
                            child.sticker && (
                              <Sticker
                                size="large"
                                packageId={child.sticker.packageId}
                                id={child.sticker.stickerId}
                              />
                            )
                          );
                        case "image":
                          return (
                            child.image && (
                              <Image src={child.image.previewImageUrl} />
                            )
                          );
                        case "video":
                          return (
                            child.video && (
                              <Video src={child.video.previewImageUrl} />
                            )
                          );
                        case "cooperation": {
                          if (!child.cooperationValues || !child.cooperationValues.type || !lang) return <></>;

                          let cooperation = cooperationTypes.find((type) => {
                            let code = type[`${lang}`.toLowerCase()].code;
                            return `${code}`.toLowerCase() === `${child.cooperationValues.type}`.toLowerCase();
                          })[lang.toLowerCase()];

                          return (
                            cooperation && (
                              <Thumbnail
                                values={{
                                  image      : cooperation.template.image,
                                  title      : cooperation.template.title,
                                  description: cooperation.template.description,
                                  button     : cooperation.template.button,
                                  href       : cooperation.template.link,
                                }}
                              />
                            )
                          );
                        }
                        case "template": {
                          return (
                            child.templateValues &&
                            child.templateValues.content && (
                              <Thumbnail
                                values={{
                                  ...child.templateValues.content,
                                  button: child.templateValues.content.button.text,
                                  href     : child.templateValues.link,
                                }}
                              />
                            )
                          );
                        }
                        case "imagemap": {
                          return (
                            child.imagemapValues &&
                            child.imagemapValues && (
                              <Imagemap
                                showDivider={showDivider}
                                SetDivider={SetDivider}
                                values={{
                                  ...child.imagemapValues,
                                }}
                              />
                            )
                          );
                        }
                        case "carousel": {
                          return (
                            child.carouselValues &&
                            child.carouselValues && (
                             <Carousel index={index} values={{
                              ...child.carouselValues,
                            }}/>
                            )
                          );
                        }
                        default:
                      }
                    })()}
                  </div>
                </div>
              </Message>
            );
          })}
      </PreviewContent>
    </PreviewContainer>
  );
};
const PreviewContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  width: 372px;
  &.dark {
    background: #666f86;
    border: none!important;
  }
  &.fixed {
    height: inherit!important;
    border-bottom: 0;
    position: fixed;
    right: 1rem;
    bottom: 0;
    z-index: 3;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  }
`;
const Message = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  .emoji-span-image::selection {
    color: transparent;
  }
  .avatar {
    img {
      width: 45px;
      height: 45px;  
      border-radius :30px;
      border-radius: 50%;
      margin-right : 10px;
    }
  }
  .message {
    position: relative;
    width: 100%;
    .title {
      font-size: 13px;
      color: #fff;
      padding: 2px;
    }
    .body {
      font-size: .875rem;
      font-family: Roboto, Meiryo, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", "Noto Color Emoji", "Noto Emoji", sans-serif, Arial;
      line-break: anywhere;
      display: inline-block;
      position: relative;
      padding: 0.5rem 0.75rem;
      background: #f2f3f5;
      border-radius: 20px;
      max-width: 300px;
      white-space: pre-wrap;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0.75rem;
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-right-color: #edeff0;
        border-left: 0;
        border-top: 0;
        margin-left: -0.375rem;
      }
    }
  }
`;
const PreviewContent = styled.div`
  width: 370px;
  height: 540px;
  overflow-y: auto;
  transition: margin 0.3s ease-in-out;
  padding: 20px 0 20px 20px;
  &.fixed {
    margin-bottom: -580px;
    padding-right: 20px;
  }
  &.open {
    margin-bottom: 0;
  }
`;
const PreviewHeader = styled.div`
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  border-bottom: 1px solid #dfdfdf;
  &.dark {
    background: #343c3f;
    border: none!important;
    .nav {
      color: #fff;
    }
  }
  .nav {
    text-decoration: none;
    font-size: 14px;
    color: #333;
    .anticon {
      margin-right: 5px;
    }
  }
  .info {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }
`;

const PreviewAccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #4d5466;
  padding: 12px 15px;
  color: #fff;
  .button {
    height: min-content;
    width: min-content;
    border-radius: 5px;
    background : #4a575d;
    font-size: x-small;
    color:#fff;
  }
`;