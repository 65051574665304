import { Alert, Button, message } from "antd";
import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { card as cardApi, paymentBlocked as paymentBlockedApi, auth } from "../../apis";
import styled from "styled-components";
import Modal from "antd/lib/modal/Modal";
import CardForm from "../Payment/components/CardForm";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default ()=> {
  const { translate } = useTranslate();
  const Multipayment = window.Multipayment;
  const [action, setAction] = React.useState([]);
  const [error, setError] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const { shopId, gmoUrl, customer } = useSelector(state => state.general);

  const onSubmit = async (formData, resetForm) => {
    setLoad(true);
    setError(null);
    let date = [];
    date[0] = formData.expiryDate.replaceAll("/", "").slice(0, 2);
    date[1] = formData.expiryDate.replaceAll("/", "").slice(2, 4);

    Multipayment.init(shopId);
    Multipayment.getToken({
      cardno      : formData.cardNumber.replaceAll(" ", ""),
      securitycode: formData.cvv,
      expire      : `${date[1]}${date[0]}`,
      holdername  : formData.holderName,
      token       : ""
    }, async (res) => {
      if (res.resultCode === "000") {
        try {
          const response = await cardApi.add({
            holder: formData.holderName,
            cardno: res.tokenObject.maskedCardNo,
            token : res.tokenObject.token
          });

          await cardApi.setChange(response._id);
          await paymentBlockedApi.unBlock(customer._id);

          setData([...data, response]);
          action([""]);
          message.success(translate("system.message.success", "Success!"));
          setLoad(false);
          resetForm({});
          await auth.me()(dispatch);
        } catch (err) {
          console.log(err);
          setLoad(false);
          setError(err);

        }
      } else {
        setError(res);
        setLoad(false);
      }
    });
  };

  return (
    <PageContainer>
      <PageHeader title={translate("payment.blocked.page", "Payment blocked")}/>
      <PageContent>
        <Container>
          <Alert message={`${translate("payment.blocked.page.alert", "The function is restricted because the payment was not completed normally.")}`} type="warning" showIcon />
          <div style={{ margin: "10px" }}>
            <div style={{ lineHeight: "35px" }}> {translate("payment.blocked.page.text.1", "1. Check payment status from here")}<Link to="/operation/payment">{translate("payment.blocked.page.text.2", "Payment Status")}</Link> </div>
            <div>  {translate("payment.blocked.page.text.3", "2.Please register a valid credit card below and make a payment.")}  </div>
            <Button onClick={()=> setAction(["method"])} style={{ margin: "20px" }} type="primary">{translate("payment.blocked.page.button", "Card registry & Payment​")}</Button>
          </div>
          <WarningAlert>
            <p >{translate("payment.blocked.page.text.4", "※Since the credit card information is not retained on the LSEG system, it is not possible to determine whether the credit card information already registered is correct.")}</p>
            <p >{translate("payment.blocked.page.text.5", "※Click for")}<a to= "https://lp.l-seg.com">{translate("payment.blocked.page.text.6", "Manual")}</a></p>
            <p >{translate("payment.blocked.page.text.7", "※If you have any questions, please contact us from the following site")}</p>
            <a>https://lp.l-seg.com/contact_us</a>
          </WarningAlert>
        </Container>
      </PageContent>

      {action[0] === "method"&&
        <Modal
          maskClosable={false}
          width={400}
          title={translate("card.save", "Add new method")}
          visible={action[0] === "method"}
          onCancel={()=>setAction([""])}
          footer={false}>
          <CardForm onSubmit={onSubmit} error={error} load={load} />
        </Modal>
      }

    </PageContainer>
  );
};

const WarningAlert =styled.div`
  background-color: #F0F0F0;
  margin-top: 18px;
  padding : 10px;

  .title {
    margin : 20px 10px;
  }
`;

const Container = styled.div`
  
  p {
    margin: 0px 10px;
  }
  a {
    margin: 0px 10px;
    border-bottom: 1px solid #1BAB5C;
  }
  .error {
    color :red;
  }
  
`;