import React from "react";
import { useHistory } from "react-router-dom";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { richmenu } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { DatePicker, Input, Radio, Space, Modal, Row, Col, message, Empty, Alert, Tag } from "antd";
import { SearchOutlined, PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { usePermission, useTranslate } from "../../hooks";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import usePlan from "../../hooks/usePlan";
import NoImage from "../../assets/no_image51.png";
import SelectList from "./SelectList";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default () => {
  const { checkRole } = usePermission();
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const { checkPermission } = usePlan();
  const [query, setQuery] = React.useState("");
  const [action, setAction] = React.useState([]);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const { customer } = useSelector(state => state.general);
  const [usable, setUsable] = React.useState(checkPermission("RICHMENU_ALIAS"));

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [defaultRichmenu, setDefaultRichmenu] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query         : "",
    richmenuStatus: "ACTIVE"
  });
  const [counts, setCounts] = React.useState({
    aliasCount   : 0,
    richmenuCount: 0
  });

  const columns = useHeader({
    translate,
    filters,
    tableTitles: {
      no           : translate("system.priority", "Priority"),
      image        : translate("richmenu.image", "Image"),
      title        : translate("system.title", "Title"),
      actions      : translate("system.action", "Actions"),
      DisplayPeriod: translate("richmenu.display", "Display period"),
      type         : translate("message.type", "Type"),
      action       : translate("system.action", "Action"),
      default      : translate("richmenu.default", "Default"),
      targetType   : translate("richmenu.target.type", "Target type"),
    },
    history,
    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          if (item.isMulti) {
            history.push(`/products/richmenu/alias/${item._id}`);
          } else {
            history.push(`/products/richmenu/${item._id}`);
          }
          break;
        }
        case "default": {
          confirm({
            icon      : <CheckCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await richmenu.setDefault(item._id);
              message.success(translate("system.message.success", "Success!"));
              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        case "remove": {
          confirm({
            width  : 750,
            icon   : false,
            content: <div>
              <Alert
                description={
                  <div>
                    <div> {translate("richmenu.confirm.remove_1", "If you delete the rich menu within the display period, the rich menu will be deleted from the user who displayed the rich menu to be deleted, and you will need to set another rich menu again.")}</div>
                    <div> {translate("richmenu.confirm.remove_2", "(If the default rich menu is set, the default rich menu will be displayed)")}</div>
                    <br/>
                    <div>{translate("richmenu.confirm.remove_3", "Is it OK？")}</div>
                  </div>}
                type="warning"
                showIcon={false}
              />
            </div>,
            // the rich menu will be deleted from the user who displayed the rich menu to be deleted, ."
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await richmenu.remove(item._id);
              message.success(translate("system.message.success", "Success!"));
              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        case "copy": {
          if (item.isMulti){
            history.push(`/products/richmenu_alias/copy/${item._id}`);
          } else {
            history.push(`/products/richmenu/copy/${item._id}`);
          }
          break;
        }
        default:
      }
    },
    disabled
  });

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const onDefaultCancel = () => {
    confirm({
      icon      : <CheckCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        await richmenu.cancel();
        message.success(translate("system.message.success", "Success!"));
        myTableRef.current.reload();
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  const onSelect = (data) => {
    confirm({
      icon      : <CheckCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        await richmenu.setDefault(data._id);
        message.success(translate("system.message.success", "Success!"));
        setAction([]);
        myTableRef.current.reload();
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  const clearRange = () => {
    setFilters({ ...filters, startDate: null, endDate: null });
    setRangePicker({ startDate: null, endDate: null });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.richmenu"></ManualLink>} title = {translate("products.richmenu", "Rich menus")} extra={[
        usable && <Button key="0" onClick={() => history.push("/products/richmenu/alias/new")} type="primary"><PlusOutlined /> {translate("richmenu.alias.create", "New rich menu alias")}</Button>,
        <Button key="1" onClick={() => history.push("/products/richmenu/new")} type="primary"><PlusOutlined /> {translate("richmenu.create", "New rich menu")}</Button>
      ]}>
        {!loading && (
          <DefaultRichMenu>
            <div className="title">{translate("richmenu.default.header", "Default richmenu")}</div>
            <div className="explain"> {translate("richmenudefault.alert.text.header", "Default richmenu is shown to the new followers. If Default richemenu is not set not richmenu is show to new followers.")}</div>
            <div className="body">
              <div className="image">
                <img
                  src={defaultRichmenu?.image ? defaultRichmenu.image : NoImage}
                  alt="content"
                  width="100%" />
              </div>
              <div className="detail">
                <Row className="row">
                  <Col className="label">{translate("richmenu.name", "Name:")}</Col>
                  <Col className="value">{defaultRichmenu?.name || "-"}</Col>
                </Row>
                <Row className="row">
                  <Col className="label">{translate("richmenu.display", "Display period:")}</Col>
                  <Col className="value">
                    {defaultRichmenu?.richmenuSentDate ? <span className="date">{moment(defaultRichmenu.richmenuSentDate).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span> : "-"}
                    {defaultRichmenu?.schedules ? <span className="date">{moment(defaultRichmenu.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>: "-"}
                  </Col>
                </Row>
                {defaultRichmenu?.areas.map((area) => {
                  switch (area.action && area.action.type) {
                    case "uri":
                      return (
                        <Row className="row">
                          <Col className="label">{translate("richmenu.action.uri", "URI:")}</Col>
                          <Col className="value">{area.action.uri}</Col>
                        </Row>
                      );
                    case "message":
                      return (
                        <Row className="row">
                          <Col className="label">{translate("richmenu.action.message", "Text:")}</Col>
                          <Col className="value">{area.action.text}</Col>
                        </Row>
                      );
                    default:
                      return <></>;
                  }
                })}
              </div>
              <div className="action">
                {defaultRichmenu && disabled && <Button type="default" onClick={onDefaultCancel}>{translate("system.cancel", "Cancel")}</Button>}
                {!defaultRichmenu && <Button type="default" onClick={() => setAction(["setDefault"])}>{translate("richmenu.set.default", "Set default")}</Button>}
              </div>
            </div>
          </DefaultRichMenu>
        )}
      </PageHeader>
      <ManualDesc translateKey ="manual.desc.richmenu" />
      <ManualAlert translateKey={"manual.alert.richmenu"} />
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
          <Radio.Group defaultValue={filters.richmenuStatus} onChange={e => setFilters({ ...filters, richmenuStatus: e.target.value })}>
            <Radio.Button value="ALL">{translate("richmenu.all", "All")}</Radio.Button>
            <Radio.Button value="SCHEDULED">{translate("richmenu.schedule", "Scheduled")}</Radio.Button>
            <Radio.Button value="DRAFT">{translate("richmenu.draft", "Drafts")}</Radio.Button>
            <Radio.Button value="ACTIVE">{translate("richmenu.active", "Active")}</Radio.Button>
          </Radio.Group>
        </Space>

        <Space>
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          <Button type="default" onClick={() => clearRange()}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <Container>
          {checkRole(["AGENCY", "ADMIN"]) && customer && (
            <Space style={{ marginBottom: "5px" }}>
              {counts.richmenuCount < 990 ? <Tag color="green">{translate("richmenu.richmenus.count", "Richmenu count: ")}{counts.richmenuCount}/1000</Tag> :<Tag color="red">{translate("richmenu.richmenus.count", "Richmenu count: ")}{counts.richmenuCount}/1000</Tag> }
              {counts.aliasCount < 990 ? <Tag color="green" >{translate("richmenu.alias.count", "Alias count: ")}{counts.aliasCount}/1000</Tag> : <Tag color="red" >{translate("richmenu.alias.count", "Alias count: ")}{counts.aliasCount}/1000</Tag>}
            </Space>
          )}
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            filters={filters}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={richmenu.list}
            onResult={(result) => {
              setDefaultRichmenu(result.default);
              setLoading(false);
              setCounts({ richmenuCount: result.richmenuCount, aliasCount: result.aliasCount });
            }}
          />
        </Container>
        <Modal
          maskClosable={false}
          width={1200}
          visible={action && action[0] === "setDefault"}
          onCancel={() => setAction([])}
          destroyOnClose
          footer={false}>
          <SelectList onSelect={onSelect}></SelectList>
        </Modal>
      </PageContent>
    </PageContainer>
  );
};
const useHeader = ({ onAction, filters, translate, tableTitles, disabled }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.image,
    render: (record) => {
      return (
        <>
          <ImageContainer>
            <img style={{ width: "92px" }} src={record.image.indexOf("http") === 0 ? record.image : `/uploads/${record.image}`} />
          </ImageContainer>
        </>
      );
    }
  }, {
    title    : tableTitles.title,
    dataIndex: "name",
  }, {
    title : tableTitles.DisplayPeriod,
    render: (record) => {
      if (record.sendType === "NOW") {
        return <>
          { <span className="date">{record.richmenuStatus === "DRAFT" ? translate("richmenu.list.dats.sendnow", "Set at send") : moment(record.richmenuSendDate).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
          <span className="date">{moment(record.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>
        </>;
      }
      if (moment(record.schedules[1]).format("YYYY-MM-DD HH:mm") !== moment(record.schedules[0]).format("YYYY-MM-DD HH:mm")) {
        return (
          <>
            {record.schedules[0] && <span className="date">{moment(record.schedules[0]).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
            <span className="date">{moment(record.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>
          </>
        );
      }

      return (
        <>
          {record.schedules[0] && <span className="date">{moment(record.richmenuSentDate).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
          <span className="date">{moment(record.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>
        </>
      );

    }
  }, {
    title : tableTitles.targetType,
    render: (record) => (
      <div>{translate(`system.constant.${record.targetType}`, record.targetType)}</div>
    )
  }, {
    title : tableTitles.type,
    render: (record) => {
      return (
        <div>{translate(`system.constant.${record.type}`, record.type)}{record.isMulti ? translate("richmenu.ismutli", "・Multi") : ""}</div>
      );
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (record) => {
      let actions = {
        edit: translate("system.edit", "Edit"),
        // remove: translate("system.delete", "Remove"),
        copy: translate("richmenu.copy", "Copy"),
      };
      let now = moment();
      if (filters.richmenuStatus === "ACTIVE" && now < moment(record?.schedules[1]) && disabled)
        actions.default = translate("richmenu.default", "Set default");
      if (disabled){
        actions.remove = translate("system.delete", "Remove");
      }
      return (
        <div>
          <RowAction actions={actions} onClick={(key) => onAction(key, record)} />
        </div>
      );
    }
  }];
};

const ImageContainer =styled.div`
  width: 92px;
  height: 63px;
  background-color: #FAFAFA;
  display : flex;
  align-items: center; 
`;
const DefaultRichMenu = styled.div`
  padding: 20px;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  margin-bottom: 20px;
  .body {
    display: flex;
    flex-direction: row;
  }
  .image {
    width: 160px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .explain {
    font-size: 12px;
    color: red;
    margin-bottom: 5px;
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    .row {
      margin-bottom: 5px;
    }
    .label {
      font-size: 14px;
      margin-right: 10px;
      width: 100px;
      text-align: right;
      color: #333;
    }
    .value {
      font-size: 14px;
      color: ${colors.primaryDark};
      font-weight: 400;
    }
  }
`;

const Container = styled.div`
// ant-table-row-expand-icon ant-table-row-expand-icon-collapsed
  .ant-table-row-expand-icon {
    display: none;
  }
`;

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
