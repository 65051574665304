import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { scenario as scenarioApi } from "../../apis";
import { Empty, Input, Space, Select, Spin, Tag, Badge, message, Alert } from "antd";
import { SearchOutlined, CaretRightOutlined, CaretDownOutlined, SwapRightOutlined } from "@ant-design/icons";
import { Button } from "../../components/Design";
import { useFetch, useTranslate } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { tags as tagsApi, account as accountApi, } from "../../apis";
import CustomTable from "../../components/CustomTable";
import styled from "styled-components";
import Target from "../../components/Message/Target";
import moment from "moment";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { Option } = Select;

export default () => {
  const myTableRef = React.useRef();
  const dispatch = useDispatch();
  const { translate, lang } = useTranslate();
  const [eventTypeList, setEventTypeList] = React.useState({});
  const { processEvents, tags } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [accountSetting, setAccountSetting] = React.useState(false);

  useFetch(tagsApi.select(dispatch))();

  const getAccountSetting = async () => {
    try {
      const result = await accountApi.setting();
      setAccountSetting(result);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };

  React.useEffect(() => {
    getAccountSetting();
  }, []);

  const [filters, setFilters] = React.useState({
    query: "",
    hide : false
  });

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const onChangeEvent = (type) => {
    setFilters({
      ...filters,
      eventType: type
    });
  };

  const onChangeIsShow =(type)=> {
    setFilters({
      ...filters,
      hide: type
    });
  };

  React.useEffect(()=> {
    setEventTypeList(convertArrayToObject(processEvents, "code"));
  }, [processEvents]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.scenarioreport"></ManualLink>} title={translate("scenario.report", "Scenarion report")} />
      <ManualDesc translateKey ="manual.desc.scenarioreport" />
      <ManualAlert translateKey={"manual.alert.scenarioreport"} />
      <AlertContainer>
        <Alert type="warning" message={
          <div>
            {moment(accountSetting.processReportGenerateDate).format("YYYY/MM/DD HH:mm")}
          </div>
        } />
      </AlertContainer>

      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
          <Select name="eventType" style={{ width: 180 }} onChange={onChangeEvent} placeholder= {translate("scenario.report.filter.placeholder", "All scenario")} allowClear>
            {processEvents.map((event, index) => <Option value={event.code} key={index}>{event[`${lang?.toLowerCase()}`]}</Option>)}
          </Select>
          <Select name="eventType" defaultValue={filters.hide} style={{ width: 180 }} onChange={onChangeIsShow} placeholder= {translate("scenario.report.filter.placeholder", "All scenario")}>
            <Option value={false} key={1}>{translate("scenario.list.filter.show", "Show")}</Option>
            <Option value={true} key={2}>{translate("scenario.list.filter.hide", "Hide")}</Option>
            <Option value={null} >{translate("system.all", "All")}</Option>
          </Select>
        </Space>
      </PageFilter>
      <PageContent>
        <ScenarioTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          width="2000px"
          loadData={scenarioApi.report}
          bordered
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
                <th className="ant-table-cell" rowSpan={2}>{translate("scenario.report.name", "Scenario name")}</th>
                <th className="ant-table-cell" rowSpan={2}>{translate("scenario.report.events", "Event type")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.users", "Users")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step1", "Step 1")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step2", "Step 2")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step3", "Step 3")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step4", "Step 4")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step5", "Step 5")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step6", "Step 6")}</th>
                <th className="ant-table-cell" colSpan={2}>{translate("scenario.report.step7", "Step 7")}</th>
              </tr>
              <tr>
                <th className="ant-table-cell" >{translate("scenario.report.started.users", "Start")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.ended.users", "End")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

                <th className="ant-table-cell" >{translate("scenario.report.message.count", "Message")}</th>
                <th className="ant-table-cell" >{translate("scenario.report.passed.users", "Users")}</th>

              </tr>
            </thead>
          )} tbody={(row, index) => {
            return Object.keys(eventTypeList).length > 0 && (<ScenarioItem key={index} index={index} row={row} translate={translate} eventTypeList={eventTypeList} lang={lang} tags={tags} />);
          }} />
      </PageContent>
    </PageContainer>
  );
};


const ScenarioItem =({ row, index, translate, eventTypeList, lang, tags })=> {
  const [step, setStep] = React.useState();
  const [action, setAction] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const onSelect = (index) => {
    if (step && row.steps[index]?._id === step?._id)
      return setStep(null);

    setLoading(true);

    setStep(row.steps[index] || {});
    setAction();

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const onAction = (type) => {
    if (action && action[0] === type)
      return setAction();
    setAction([type, step]);
  };

  return (
    <>
      <tbody className="ant-table-tbody">
        <tr className="tr-border">
          <td rowSpan={2}>{index + 1}</td>
          <td rowSpan={2}>
            <div style={{ display: "flex" }}>
              {row?.processStatus === "START" && (<Badge status="processing" color="green" />)}
              <div>{row.name}</div>
            </div>
          </td>
          <td rowSpan={2}>{eventTypeList[row.eventType][lang.toLowerCase()]}</td>
          <td rowSpan={2}>{row.followers.start || <span className="none" >-</span>}</td>
          <td rowSpan={2}>{row.followers.end || <span className="none" >-</span>}</td>
          <td>{row.steps[0]?.message || <span className="none">-</span>}</td>
          <td>{row.steps[0]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[1]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[1]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[2]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[2]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[3]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[3]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[4]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[4]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[5]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[5]?.followers || <span className="none" >-</span>}</td>
          <td>{row.steps[6]?.message || <span className="none" >-</span>}</td>
          <td>{row.steps[6]?.followers || <span className="none" >-</span>}</td>
        </tr>
        <tr>
          {[0, 1, 2, 3, 4, 5, 6].map(index => (
            <td key={index} colSpan={2} style={{ padding: 0 }}>
              <Button size="small" type="link" style={{ width: "100%" }} onClick={() => onSelect(index)} disabled={!row.steps[index]}>
                {translate("scenario.report.more", "More")} {step && row.steps[index]?._id === step?._id ? <CaretDownOutlined /> : <CaretRightOutlined />}
              </Button>
            </td>
          ))}
        </tr>
      </tbody>

      {loading && (
        <tbody>
          <tr>
            <td colspan="19">
              <Spin spinning={loading}>
                <div style={{ width: "100%", height: 100 }}></div>
              </Spin>
            </td>
          </tr>
        </tbody>
      )}

      {!loading && step && step.isJudgement === true && (
        <tbody>
          <tr>
            <td colspan="18">
              <table className="ant-table ant-table-bordered">
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" style={{ textAlign: "left" }} colSpan={2}>{translate("scenario.report.judgement", "Judgement")}</th>
                    <th className="ant-table-cell" rowSpan={1}>{translate("scenario.report.parts.users", "Users")}</th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {(() => {
                    return (
                      <>
                        <tr>
                          <td rowSpan={3} style={{ borderLeft: "1px solid #F0F0F0", borderRight: "1px solid #F0F0F0", width: 480 }}>
                            <Target editable={["update", { segments: step.judgement?.targets }]} onlyCustomSegments disabled={true} />
                          </td>
                          <td>
                            <Button onClick={() => onAction("then")} type="link">{translate("scenario.report.then", "Then")} {action && action[0] === "then" ? <CaretDownOutlined /> : <CaretRightOutlined />}</Button>
                          </td>
                          <td style={{ width: 100 }}>{step.judgement.thens || "-"}</td>
                        </tr>

                        {action && action[0] === "then" && !loading && step && (
                          <>
                            <Children colspan={2} translate={translate} children={step?.judgement?.children.filter(child => child.condition === action[0])} tags={tags} action={action} />
                          </>
                        )}

                        <tr>
                          <td>
                            <Button onClick={() => onAction("catch")} type="link">{translate("scenario.report.catch", "Catch")} {action && action[0] === "catch" ? <CaretDownOutlined /> : <CaretRightOutlined />}</Button>
                          </td>
                          <td style={{ width: 100 }}>{step.judgement.catchs || "-"}</td>
                        </tr>

                        {action && action[0] === "catch" && !loading && step && (
                          <>
                            <Children colspan={2} translate={translate} children={step?.judgement?.children.filter(child => child.condition === action[0])} tags={tags} action={action} />
                          </>
                        )}
                      </>
                    );
                  })()}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      )}

      {!loading && step && (
        <>
          <Children translate={translate} children={step?.children} tags={tags} colspan={19} />
        </>
      )}
    </>
  );
};

const renderTags = ({ child, tags, translate }) => {
  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };

  return <>
    <span style={{ marginRight: 5 }}>{translate("scenario.report.action.type.tag", "Auto tag:")}</span>
    {child.tags.map((item, index) => {
      console.log("ITEM", item);
      let tag = tags.find(tag => tag._id === item.tag);
      if (!tag.value){
        tag.values.forEach(val => {
          if (item?.value === val?._id)
            tag = val;
        });
      }
      console.log("TAG", tag);
      if (!tag) return (
        <Tag
          key={index}
          style={{ marginBottom: 4 }}
          color="red">
          {translate("tag.deleted", "Deleted")}
        </Tag>
      );

      switch (tag.type) {
        case "NUMBER": {
          return (
            <Tag
              key={index}
              style={{ marginBottom: 4 }}
              color="cyan">
              {tag.name}:{item.value}
            </Tag>
          );
        }
        case "CHECKBOX": {
          return (
            <Tag
              key={index}
              style={{ marginBottom: 4 }}
              color="orange">
              {tag.name}:{tag.checked ? "NO": "YES"}
            </Tag>
          );
        }
        case "DATE": {
          return (
            <Tag
              key={index}
              style={{ marginBottom: 4 }}
              color="lime">
              {tag.auto === undefined ? tag.name : translate("default.tag.registeredDate", tag.name)}:{moment(tag.date).format("YYYY-MM-DD")}
            </Tag>
          );
        }
        case "SELECT": {
          return (
            <Tag
              key={index}
              style={{ marginBottom: 4 }}
              color="lime">
              {tag.name}:{tag.number}
            </Tag>
          );
        }
        default:
          return (
            <Tag
              key={index}
              style={{ marginBottom: 4 }}
              color="green">
              {(() => {
                if (tag.auto && tag.auto === "ages")
                  return `${tag.name}${name(tag)}`;
                if (tag.auto && tag.auto === "subscriptionPeriods")
                  return `${tag.name}${name(tag)}`;
                if (tag.auto)
                  return translate(`default.tag.${tag.value}`, tag.name);

                return tag.name;
              })()}
            </Tag>
          );
      }
    })}
  </>;
};

const Children = ({ children, action, translate, colspan, tags }) => {
  return (
    <tr >
      <td colspan={colspan}>
        <table className="ant-table ant-table-bordered">
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell" style={{ textAlign: "left" }} colSpan={3}>{action ? <>{translate("scenario.report.judgement", "Judgement")} [{action[0]}] <SwapRightOutlined /> {translate("system.detail", "Details")}</> : <>{translate("system.detail", "Details")}</>}</th>
              <th className="ant-table-cell" rowSpan={1}>{translate("scenario.report.parts.users", "Users")}</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            {(() => {
              const Action = ({ child }) => {
                if (child.type === "next")
                  return `${translate("scenario.report.action.type.next", "next")} (${child.nextStep + 1})`;

                if (child.type === "tag") {
                  return renderTags({ child, tags, translate });
                }

                return child.name || translate(`scenario.report.action.type.${child.type}`, child.type);
              };

              return (
                <>
                  {children.sort((a, b) => a.index - b.index).map((iterator, index) => {
                    if (iterator.type === "condition") {
                      let thens = iterator?.children.filter(child => child.condition === "then");
                      let catchs = iterator?.children.filter(child => child.condition === "catch");

                      return (
                        <>
                          {thens.sort((a, b) => a.index - b.index).map((child, index) => {
                            if (index === 0) {
                              return (
                                <tr>
                                  <td width={480} rowSpan={iterator.children.length} style={{ borderLeft: "1px solid #F0F0F0", borderRight: "1px solid #F0F0F0", width: 480 }}>
                                    <Target editable={["update", { segments: iterator?.targets }]} onlyCustomSegments disabled={true} />
                                  </td>
                                  <td rowSpan={thens.length} style={{ borderRight: "1px solid #F0F0F0" }}>{translate("scenario.report.then", "Then")}</td>
                                  <td>{Action({ child })}</td>
                                  <td style={{ width: 100 }}>{child.followers || "-"}</td>
                                </tr>
                              );
                            }

                            return (
                              <tr key={index}>
                                <td>{Action({ child })}</td>
                                <td style={{ width: 100 }}>{child.followers || "-"}</td>
                              </tr>
                            );
                          })}

                          {catchs.sort((a, b) => a.index - b.index).map((child, index) => {
                            if (index === 0) {
                              return (
                                <tr>
                                  <td rowSpan={catchs.length} style={{ borderRight: "1px solid #F0F0F0" }}>{translate("scenario.report.catch", "Catch")}</td>
                                  <td>{Action({ child })}</td>
                                  <td style={{ width: 100 }}>{child.followers || "-"}</td>
                                </tr>
                              );
                            }

                            return (
                              <tr key={index}>
                                <td>{Action({ child })}</td>
                                <td style={{ width: 100 }}>{child.followers || "-"}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    }

                    return (
                      <tr>
                        <td colSpan={3}>{Action({ child: iterator })}</td>
                        <td style={{ width: 100 }}>{iterator.followers || "-"}</td>
                      </tr>
                    );
                  })}
                </>
              );
            })()}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const ScenarioTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 2px 6px!important;
  }
  
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 2px 16px !important;
  }

  .tr-border { 
     td {
      border-top: 0.1px solid #cbcbcb;
    }
  }
  tbody > tr > td {
    border-bottom: 1px solid #F0F0F0;
  }
  .sub-table{
    border: solid 1px #F0F0F0;
    border-radius: 5px;
    table {
      width :500px;
    }
    thead> tr > th {
      padding: 16px!important;
      font-weight : 500;
      background-color :#FBFBFB !important;
      border-bottom: 1px solid #F0F0F0;
      border-right: solid 1px #f2f1f1;
    }
    tbody> tr > td {
      padding: 4px!important;
      /* background-color :#c6bebef5 !important; */
      border-bottom: 1px solid #FBFBFB;
      border-right: solid 1px #f2f1f1;
    }
  }
  .none {
    color :#BFBFBF;
  }
`;

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
const AlertContainer = styled.div`
  color: #5a5d61;
  margin: 0px 24px 12px;
`;