import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { message, Space, Input, Empty, Checkbox, Tag } from "antd";
import { Button } from "../../components/Design";
import { useHistory } from "react-router-dom";
import { news } from "../../apis";
import { useTranslate } from "../../hooks";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import MyTable from "../../components/MyTable";
import confirm from "antd/lib/modal/confirm";
import moment from "moment";

export default () => {
  const myTableRef = React.useRef(null);
  const { translate } = useTranslate();
  const history = useHistory();
  const [query, setQuery] = React.useState("");

  const isPriority = (e, item) => {
    item.isPriority = e.target.checked;
    news.update(item);
    console.log(e.target.checked);
  };

  const column = useHeader({
    history,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      title : translate("system.title", "Title"),
      desc  : translate("announcement.description", "Description"),
      action: translate("system.action", "Action"),
      date  : translate("message.createddate", "Date"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
      view  : translate("news.view", "View"),
      status: translate("system.status", "Status"),
      hide  : translate("news.hide", "Hide"),
      show  : translate("news.show", "Show"),
    },
    isPriority: isPriority,
    onAction  : async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/admin/news/${item._id}`);
          break;
        }
        case "show": {
          item.hide = !item.hide;
          await news.update(item);
          message.success(translate("system.message.success", "Successful"));
          myTableRef.current.reload();
          break;
        }
        case "hide": {
          item.hide = !item.hide;
          await news.update(item);
          message.success(translate("system.message.success", "Successful"));
          myTableRef.current.reload();
          break;
        }
        case "view": {
          history.push(`/news/detail/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon      : <ExclamationCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await news.remove(item._id);

              message.success(translate("system.message.success", "Successful"));

              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        default:
      }
    },
  });

  const addAnnouncements = () => {
    history.push("/admin/news/new");
  };

  return (
    <div>
      <PageContainer>
        <PageHeader title={translate("dashboard.announcements", "Announcements")} extra={[
          <Button key="new" onClick={addAnnouncements} type="primary">{translate("announcement.new", "Add announcement")}</Button>
        ]} />
        <PageFilter>
          <Space>
            <Input.Group compact>
              <Input
                name="query"
                placeholder={translate("system.search.button", "Search...")}
                onChange={e => setQuery(e.target.value)}
                style={{ width: 250 }}
                prefix={<SearchOutlined />}
              />
            </Input.Group>
          </Space>
          <Space>
          </Space>
        </PageFilter>
        <PageContent>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            // filters={filters}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={column}
            loadData={news.list}
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};
const useHeader = ({ onAction, tableTitles, isPriority, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.date,
    render: (item) => {
      return (
        <div>
          {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
      );
    }
  }, {
    title    : tableTitles.desc,
    dataIndex: "desc",
  }, {
    title : tableTitles.status,
    render: (item) => {
      return (
        <div>
          {(() => {
            if (item.priority === true) {
              return <Tag color="green">{translate("news.priority", "Priority")}</Tag>;
            }
            return null;
          })()}
          {(() => {
            if (item.hide === true) {
              return <Tag color="orange">{translate("news.hide", "Hidden")}</Tag>;
            }

            return null;

          })()}
        </div>
      );
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => {
      if (item.hide === true) {
        return <RowAction actions={{
          edit  : tableTitles.edit,
          remove: tableTitles.remove,
          view  : tableTitles.view,
          show  : tableTitles.show
        }} onClick={(key) => onAction(key, item)} />;
      }

      return <RowAction actions={{
        edit  : tableTitles.edit,
        remove: tableTitles.remove,
        view  : tableTitles.view,
        hide  : tableTitles.hide
      }} onClick={(key) => onAction(key, item)} />;

    }
  }];
};
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;