import React from "react";
import { Form, Radio } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { formItemLayout } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { target, tagForm as tagFormApi, rakutencategoryApi } from "../../apis";
import { useTranslate } from "../../hooks";
import { Form as AntForm, Tag, Modal, Button } from "antd";
import { EditOutlined, CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import TargetForm from "./components/TargetForm";
import DemographicForm, { INIT_DEMOGRAPHIC } from "./components/DemographicForm";
import { SwapRightOutlined } from "@ant-design/icons";
import ImportTag from "../../pages/TargetForm/List";
import moment from "moment";

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({ 
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const { editable, onlyCustomSegments, customName, onSubmit, disabled, type, hideNarrowcast=false} = props;
  const { tags, demographics } = useSelector(state => state.general);
  const [action, setAction] = React.useState();
  const dispatch = useDispatch();
  const submitRef = React.useRef();
  const [categoryData, setCategoryData] = React.useState({
    categoryTree     : [],
    rakutenCategories: []
  });
  const fetchCategoryData = async () => {
    try {
      const {  rakutenCategories } = await rakutencategoryApi.tree();
      setCategoryData({ rakutenCategories });
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  React.useEffect(() => {
    fetchCategoryData();
  }, []);

  const [data, setFormData] = React.useState({
    targetType : onlyCustomSegments !== undefined ? "SEGMENTS" : "ALL",
    demographic: {
      ...INIT_DEMOGRAPHIC
    },
    segments: [],
    ...(editable && (editable[0] === "update" || editable[0] === "reply" || editable[0] === "copy") ? editable[1] : {})
  });

  const onSegments = (segment) => {
    const VARIABLE = {
      TOTAL_AMOUNT: {
        totalAmount: segment.totalAmount
      },
      AVERAGE_AMOUNT: {
        averageAmount: segment.averageAmount
      },
      TOTAL_PURCHASES: {
        totalPurchases: segment.totalPurchases
      },
      COUPON_USAGES: {
        couponUsages: segment.couponUsages
      },
      PURCHASES: {
        purchases     : segment.purchases,
        purchaseValues: segment.purchaseValues
      }
    };

    setFormData({
      ...data,
      segments: [...(data.segments !== undefined ? data.segments : []), {
        isRakuten: segment.isRakuten,
        ...((segment.tags || []).length > 0 ? {
          tags     : segment.tags,
          tagValues: segment.tagValues
        } : {}),
        types: segment.types,
        ...(segment.types || []).reduce((accumulator, iterator, index) => {

          return {
            ...accumulator,
            ...(VARIABLE[iterator] || {})
          };
        }, {})
      }]
    });

    setAction([]);
  };

  const onUpdateSegments = async (segment) => {
    const VARIABLE = {
      TOTAL_AMOUNT: {
        totalAmount: segment.totalAmount
      },
      AVERAGE_AMOUNT: {
        averageAmount: segment.averageAmount
      },
      TOTAL_PURCHASES: {
        totalPurchases: segment.totalPurchases
      },
      COUPON_USAGES: {
        couponUsages: segment.couponUsages
      },
      PURCHASES: {
        purchases     : segment.purchases,
        purchaseValues: segment.purchaseValues
      }
    };

    let update = {
      ...data,
    };

    update.segments[segment.index] = {
      isRakuten: segment.isRakuten,
      ...((segment.tags || []).length > 0 ? {
        tags     : segment.tags,
        tagValues: segment.tagValues
      } : {}),
      types: segment.types,
      ...(segment.types || []).reduce((accumulator, iterator, index) => {

        return {
          ...accumulator,
          ...(VARIABLE[iterator] || {})
        };
      }, {})
    };
    setFormData({ ...update });
    setAction([]);
  };

  const onDemographic = async (demographic) => {
    setFormData({
      ...data,
      demographic: {
        ...data.demographic,
        ...(Object.keys(demographic).filter(type => demographic[type])).reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator]: demographic[iterator]
          };
        }, {})
      }
    });

    setAction([]);
  };

  const onChangeTargetType = (type) => {
    let estimation = {}

    if (type === "SEGMENTS") {
      estimation = {
        ...data,
        targetType: type
      }
    } else {
      estimation = {
        ...data,
        segments :[],
        targetType: type
      }
    }
  
    setFormData(estimation);

    dispatch({
      type   : "message.target.type",
      payload: type
    });
  };

  React.useEffect(() => {
    const handler = setTimeout(async () => {
      // let isValid = await submitRef.current.submitForm();
      // if (!isValid || estimate === undefined) return;
        
      dispatch({
        type   : "message.target.calculating",
        payload: true
      });

      let res;

      try {
        if (data.targetType === "DEMOGRAPHIC")
          res = await target.demographic(data.demographic);
          
        if (data.targetType === "SEGMENTS") {
          res = await target.segment(data.segments?data.segments:[]);
        } else if(type === "message" && data.targetType === "ALL"){
          res = await target.broadcast();
        } else if(!type && data.targetType === "ALL")
          res = await target.segment(data.segments?data.segments:[]);

        setTimeout(() => {
          dispatch({
            type   : "message.target.estimate",
            payload: {
              percent: Math.round(res.target * 100 / res.total),
              friends: res.target
            }
          });
        }, 500);

        if (onSubmit) onSubmit(data);
      } catch (err) {
        dispatch({
          type   : "message.target.calculating",
          payload: false
        });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [dispatch, data]);

  const renderDemographic = () => {
    return Object.keys(demographics).map((type, index) => {
      if (!data.demographic[type] || data.demographic[type].length <= 0)
        return <></>;

      let results = demographics[type].values.reduce((accumulator, iterator) => {
        if ((data.demographic[type] || []).indexOf(iterator.value) !== -1)
          return [...accumulator, iterator];

        return accumulator;
      }, []);

      return (
        <div className="data" key={index}>
          <div className="header">
            <label className="title">{translate(`system.constant.demo.${data.demographic[type].code}`, data.demographic[type].name)}</label>
            {!disabled && (
              <div style={{ display: "flex" }}>
                <Button className="button" onClick={() => setAction(["demographic", { type, [type]: data.demographic[type] }])} disabled={disabled}><EditOutlined /></Button>
                <Button className="button" onClick={() => setFormData({
                  ...data,
                  demographic: {
                    ...data.demographic,
                    [type]: []
                  }
                })} disabled={disabled}><CloseCircleOutlined /></Button>
              </div>
            )}
          </div>
          <div className="body">
            {(() => {
              switch (type) {
                case "areas":
                  return results.map((item, index) => (<Tag key={index}>{translate(`default.tag.${item.value}`, item.label.en)}</Tag>));
                case "ages":
                  return results.map((item, index) => (<Tag key={index}>{translate(`default.tag.${item.value}`, `${item.value} ${translate("tag.ages", "age")}`)}</Tag>));
                case "subscriptionPeriods":
                  return results.map((item, index) => (<Tag key={index}>{translate(`default.tag.${item.value}`, `${item.value} ${translate("tag.days", "day")}`)}</Tag>));
                default:
                  return results.map((item, index) => (<Tag key={index}>{translate(`default.tag.${item.value}`, item.label)}</Tag>));
              }
            })()}
          </div>
        </div>
      );
    });
  };

  const renderSegments = () => {
    return data.segments?.map((segment, index) => {
      return (
        <div className="data" key={index}>
          <div className="header">
            <label className="title">{translate("target.custom.segment", "Custom segment")} {index + 1}</label>
            {!disabled && (
              <div style={{ display: "flex" }}>
                <Button className="button" onClick={() => {
                  setAction(["onUpdateSegments", { ...segment, index: index }]);
                }} disabled={disabled}><EditOutlined /></Button>
                <Button className="button" onClick={() => {
                  setFormData({
                    ...data,
                    segments: data.segments.filter((s, i) => i !== index)
                  });
                }} disabled={disabled}><CloseCircleOutlined /></Button>
              </div>
            )}
          </div>
          <div className="body">
            {segment.isRakuten === true && <Tag key={index}>{translate("target.rakuten.user", "Rakuten user")}{translate("system.yes", "Yes")}</Tag>}
            {segment.isRakuten === false && <Tag key={index}>{translate("target.rakuten.user", "Rakuten user")}{translate("system.no", "No")}</Tag>}
            {(segment.types || []).map((type, index) => {
              switch (type) {
                case "TOTAL_AMOUNT": {
                  return (
                    <Tag key={index}>
                      {translate("target.total.amount", "Total amount")}: {segment.totalAmount.value[0]} <SwapRightOutlined /> {segment.totalAmount.value[1]}{segment.totalAmount.exclusion && <span className="exclusion">({translate("target.exclusion", "Exclude")})</span>}
                    </Tag>
                  );
                }
                case "AVERAGE_AMOUNT": {
                  return (
                    <Tag key={index}>
                      {translate("target.average.purchase", "Average purchase")}: {segment.averageAmount.value[0]} <SwapRightOutlined /> {segment.averageAmount.value[1]}{segment.averageAmount.exclusion && <span className="exclusion">({translate("target.exclusion", "Exclude")})</span>}
                    </Tag>
                  );
                }
                case "COUPON_USAGES": {
                  return (
                    <Tag key={index}>
                      {translate("target.coupon.usage", "Coupon Usage")}: {segment.couponUsages.value[0]} <SwapRightOutlined /> {segment.couponUsages.value[1]}{segment.couponUsages.exclusion && <span className="exclusion">({translate("target.exclusion", "Exclude")})</span>}
                    </Tag>
                  );
                }
                case "TOTAL_PURCHASES": {
                  return (
                    <Tag key={index}>{translate("target.totalPurchase", "Total purchase")} : {segment.totalPurchases.value[0]} <SwapRightOutlined /> {segment.totalPurchases.value[1]}{segment.totalPurchases.exclusion && <span className="exclusion">({translate("target.exclusion", "Exclude")})</span>}</Tag>
                  );
                }
                case "PURCHASES": {
                  return segment.purchases.map((id, index) => {
                    let category = categoryData.rakutenCategories.find(category => category._id === id);

                    if (!category || !segment.purchaseValues[index]) return <></>;

                    return (
                      <Tag key={index}>{category.name}: {segment.purchaseValues[index].value[0]} <SwapRightOutlined /> {segment.purchaseValues[index].value[1]}{segment.purchaseValues[index].exclusion && <span className="exclusion">({translate("target.exclusion", "Exclude")})</span>}</Tag>
                    );
                  });
                }
                default:
                  return <></>;
              }
            })}
            {(segment.tags || []).map((id, index) => {
              let tag = tags.find(tag => tag._id === id);
              switch (tag?.type) {
                case "SELECT": {
                  let value = tag.values.find(tag => tag._id === segment.tagValues[index].value);
                  return (
                    value ? <Tag key={index}>{translate(`default.tag.${tag.auto}`,tag.name)}: {translate(`default.tag.${value?.value}`,value?.name)} {segment.tagValues[index]?.exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag> 
                    :<Tag color="red" key={index}>{translate(`default.tag.${tag.auto}`,tag.name)}: {translate('target.set.targetvalue', "Set target Value")} {segment.tagValues[index]?.exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag> 
                  );
                }
                case "NUMBER": {
                  return (
                    <Tag key={index}>{translate(`default.tag.${tag.auto}`,tag.name)}: {segment.tagValues[index].value[0]} <SwapRightOutlined /> {segment.tagValues[index].value[1]} {segment.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                  );
                }
                case "CHECKBOX": {
                  return (
                    <Tag key={index}>{translate(`default.tag.${tag.auto}`,tag.name)}: {segment.tagValues[index].value ? `${translate("system.yes","Yes")}` : `${translate("system.no","No")}`}</Tag> 
                  );
                }
                case "DATE": {
                  return (
                    <Tag key={index}>{translate(`default.tag.${tag.auto}`,tag.name)}: {moment(segment.tagValues[index].value[0]).format("YYYY-MM-DD")} <SwapRightOutlined /> {moment(segment.tagValues[index].value[1]).format("YYYY-MM-DD")} {segment.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                  );
                }
                case "TAG": {
                  return (
                    <Tag key={index}>{tag.name}{segment.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                  );
                }
                default:
                  return <></>;
              }
            })}
          </div>
        </div>
      );
    });
  };

  const onImportTargets = async (item)=> {
    let res = await tagFormApi.get(item._id);

    setFormData({ ...data, segments: [...(data.segments?data.segments:[]), ...res.targets], targetType: "SEGMENTS" });
    setAction(null);
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {

      if (data.targetType === "SEGMENTS" && data?.segments?.length > 0)
        return {
          targetType: data.targetType,
          targets   : data.segments
        };

      if (data.targetType === "DEMOGRAPHIC" && (
        data.demographic?.subscriptionPeriods?.length > 0
        || data.demographic?.ages?.length > 0
        || data.demographic?.areas?.length > 0
        || data.demographic?.genders?.length > 0
        || data.demographic?.appTypes?.length > 0
      ))
      return {
        targetType: data.targetType,
        targets   : data.demographic
      };
      if (data.targetType === "ALL") {
        return {
          targetType: "ALL"
        };
      }

      return false;
    }
  }));

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={() => { }}>
        {({ values }) => {

          return (
            <Form {...formItemLayout} layout="horizontal">
              <AntForm.Item label={customName || translate("system.search.button", "Target")}>
                <Radio.Group name="targetType" className="target" onChange={(e) => onChangeTargetType(e.target.value)}>

                  {!onlyCustomSegments &&
                    <Radio value="ALL">
                      <span className="all">{translate("privatechat.all", "All friends")}</span>
                    </Radio>
                  }

                  {!onlyCustomSegments && !hideNarrowcast && (
                    <Radio value="DEMOGRAPHIC">
                      <span className="all">{translate("narrowcast", "Narrowcast")}</span>
                    </Radio>
                  )}

                  {values.targetType === "DEMOGRAPHIC" && (
                    <FormData>
                      <Button type="primary" onClick={() => setAction(["demographic"])}>{translate("system.add", "Add filter")}</Button>
                      {renderDemographic()}
                    </FormData>
                  )}

                  {!onlyCustomSegments && (
                    <Radio value="SEGMENTS">
                      <span className="all">{translate("target.custom.segment", "Custom segment")}</span>
                    </Radio>
                  )}

                  {!disabled && values.targetType === "SEGMENTS" && (
                    <div style={{ display: "flex" }}>
                      <FormData>
                        <Button style={{ marginRight: "5px" }} type="primary" onClick={() => setAction(["segments"])} disabled={disabled || values.targetType !== "SEGMENTS"}>{translate("system.add.filter", "Add filter")}</Button>
                      </FormData>

                      <Button style={{ width: "100px" }} type="primary" onClick={() => setAction(["import"])} disabled={disabled || values.targetType !== "SEGMENTS"}>{translate("system.add.import.tag", "Import tags")}</Button>
                    </div>
                  )}

                  <FormData>
                    {values.targetType === "SEGMENTS" && renderSegments()}
                  </FormData>
                </Radio.Group>
              </AntForm.Item>

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>

      {action && action[0] === "demographic" && (
        <Modal
          maskClosable={false}
          title={translate("narrowcast", "Narrowcast")}
          onCancel={() => setAction(null)}
          visible={action && action[0] === "demographic"}
          footer={null}>
          {action && <DemographicForm action={action} onSubmit={onDemographic} onCancel={() => setAction([])} />}
        </Modal>
      )}

      {action && action[0] === "import" && (
        <Modal
          maskClosable={false}
          title={translate("system.add.import.tag.title", "Import tags")}
          onCancel={() => setAction(null)}
          visible={action && action[0] === "import"}
          footer={null}>
          {action && <ImportTag select={onImportTargets}/>}
        </Modal>
      )}

      {action && action[0] === "segments" && (
        <Modal
          maskClosable={false}
          title={translate("target.custom.segment", "Custom segment")}
          onCancel={() => setAction(null)}
          width={900}
          visible={action && action[0] === "segments"}
          footer={null}>
          {action && <TargetForm editable={["update", { ...action[1] }]} onSubmit={onSegments} onCancel={() => setAction([])} />}
        </Modal>
      )}

      {action && action[0] === "onUpdateSegments" && (
        <Modal
          maskClosable={false}
          title={translate("target.custom.segment", "Custom segment")}
          onCancel={() => setAction(null)}
          visible={action && action[0] === "onUpdateSegments"}
          footer={null}>
          {action && <TargetForm editable={["update", { ...action[1] }]} onSubmit={onUpdateSegments} onCancel={() => setAction([])} />}
        </Modal>
      )}
    </FormWrapper>
  );
});

const FormData = styled.div`
  /* margin-left: 24px; */
  .data {
    margin-top :10px;
    border: 1px solid #e6e5e5;
    display: flex; 
    flex-direction: column;
    .title {
      font-size :11px;
    }
    .header { 
      background-color:#f7f7f7;
      border-bottom: 1px solid #e6e5e5;
      height: 25px;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: space-between
    }
    .body {
      padding: 10px;
      .exclusion {
        color: red;
      }
      .ant-tag {
        margin-bottom: 10px;
      }
    }
    .button {
      height: 21px;
      width: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0px;
      border: none;
      background: #f7f7f7;
    }
  }
`;
const FormWrapper = styled.div`
  .target {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    margin-right: 0!important;
  }
  .tags {
    display: inline-flex;
    margin-top: 10px;
    span.ant-radio + * {
      width: 100%;
      padding-right: 0!important;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
  .segment-checkbox {
    margin-left :20px;
  }
  .all {
    display: inline-block;
    padding: 10px 0;
  }
  .narrowcast {
    margin-top   : 5px;
    margin-bottom: 15px;
    display: inline-flex;
    span.ant-radio + * {
      width: 100%;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
`;
