import React, { memo } from "react";
import { DualAxes } from "@ant-design/plots";

const SalesGraph = memo(({ gms, gms_conversion }) => {
  const config = {
    data           : [gms, gms_conversion],
    xField         : "date",
    yField         : ["gms", "gms_conversion"],
    geometryOptions: [
      {
        geometry        : "column",
        isStack         : true,
        seriesField     : "type",
        columnWidthRatio: 0.4,
      },
      {
        geometry   : "line",
        seriesField: "type",
      },
    ],
  };

  return <DualAxes {...config} />;
});

SalesGraph.displayName = "SalesGraph";

export { SalesGraph };
